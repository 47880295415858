import { useMemo, useState } from 'react';
import { Box, Button, Container, Divider, MenuItem, Select, Tooltip } from '@mui/material';
import { ApplicationSection, AssignmentsSection, AttentionWarningIcon, DatesSection, EventPageSection, FilesSection, InsuranceSection, JumpNav, LinksSection, LocationSection, LodgingSection, OverviewSection, PageContentContainer, PaymentsSection, PhotosSection, SalesReport, SectionCardsEditContextProvider, ToDosSection, getInitialAnalyticsTimelineChartTimePeriodForDateRange, useAlertSnackbar, useEventContext } from 'components';
import { EventPageHeader } from './components';
import { DeleteEventModal } from './components/DeleteEventModal.component';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QUERY_KEY } from 'queries/query-keys';
import { getEvent, updateEvent } from 'api/actions';
import { DateService } from 'services';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useNavigate } from 'react-router-dom';
import { useCurrentUser } from 'contexts';
import { eventPageSectionEnumHelpers, eventPageSectionRowEnumHelpers } from '../../components/Event/helpers';
import { EventsManagementTreeViewNodeIdEnum } from 'pages/EventsManagement/helpers';
import { OpenInNew } from '@mui/icons-material';

export type EventPageModalViewType = null | 'deleteEvent';

export const Event = () => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const [ view, setView ] = useState<EventPageModalViewType>(null);
  const { event, warningItems } = useEventContext();
  const queryClient = useQueryClient();
  const snackbar = useAlertSnackbar();
  const toggleAttentionMutation = useMutation({
    mutationFn: () => {
      return updateEvent(event._id, { attention: !event.attention });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(QUERY_KEY.EVENT(event._id));
      snackbar[event.attention ? 'success' : 'warning'](`Event ${event.attention ? ' unmarked ' : 'marked '} 'Attention'`);
    },
    onError: () => {
      snackbar.error('Unable to update event attention');
    }
  });

  const modalView = useMemo(() => {
    if (view === 'deleteEvent') {
      return <DeleteEventModal onClose={() => setView(null)} />;
    }

    return null;
  }, [ view ]);

  const todaysEventDate = event.dates.find(eventDate => DateService.dayjsTz(eventDate.dateAsUtc).isToday());

  const onYearChange = async (eventId: string) => {
    await queryClient.prefetchQuery(QUERY_KEY.EVENT(eventId), async () => (await getEvent(eventId)).data);

    navigate(`/${ROUTING_CONFIG.events}/${eventId}`);
  };

  const tabs = useMemo(() => {
    const getBadgeContent = (sectionId: EventPageSection) => warningItems.find(warningItem => warningItem.section === sectionId)?.reasonItems.length;

    return eventPageSectionEnumHelpers.enumValues.map(sectionId => ({
      label: eventPageSectionEnumHelpers.getLabel(sectionId),
      sectionId,
      badgeContent: getBadgeContent(sectionId),
      hidden: eventPageSectionEnumHelpers.getIsHidden(sectionId, event, currentUser),
    }));
  }, [ currentUser, event, warningItems ]);
  const isSectionHidden = (section: EventPageSection) => eventPageSectionEnumHelpers.getIsHidden(section, event, currentUser);

  return (
    <PageContentContainer
      contentSx={{ pt: 4 }}
      preventContainer
      maxWidth="lg"
      breadcrumbs={{
        prev: [ { link: ROUTING_CONFIG.events, text: 'Events' } ],
        current: event.name
      }}
      header={(
        <EventPageHeader
          stickyTabs
          tabs={(
            <JumpNav
              initialValue={EventPageSection.OVERVIEW}
              offsetTop={64}
              tabs={tabs}
            />
          )}
          pageTitle="Event Page"
          options={[
            {
              label: 'Delete Event',
              menuItemProps: { onClick: () => setView('deleteEvent') },
              labelTypographyProps: { sx: { color: theme => theme.palette.error.dark } },
              preventCloseOnClick: true
            },
            {
              label: `${event.attention ? 'Unmark' : 'Mark'} 'Attention'`,
              menuItemProps: { onClick: () => toggleAttentionMutation.mutate() },
              labelTypographyProps: { sx: { color: theme => theme.palette.warning.dark } } }
          ]}
          attentionWarningIcon={<AttentionWarningIcon warningItems={warningItems} sectionEnumHelpers={eventPageSectionEnumHelpers} rowEnumHelpers={eventPageSectionRowEnumHelpers} />}
          actions={(
            <>
              <Button onClick={() => navigate('dates')}>Dates</Button>
              <Button onClick={() => navigate('scheduling')}>Scheduling</Button>
              <Button onClick={() => navigate('sales')}>Sales</Button>
              <Button onClick={() => navigate('feedback')}>Feedback</Button>
              {todaysEventDate && <Button onClick={() => navigate(`/${ROUTING_CONFIG.loggingPortal}/event-date/${todaysEventDate._id}`)} variant="contained">Logging Portal</Button>}
            </>
          )}
          editableImage
          subHeaderContent={(
            <Select
              value={event._id}
              onChange={(event) => onYearChange(event.target.value)}
              variant="standard"
            >
              {event.eventCollection.events.map((event) => {
                return (
                  <MenuItem
                    key={event._id}
                    value={event._id}
                  >
                    {event.year}
                  </MenuItem>
                );
              })}
              <Box mx={1}>
                <Tooltip title="Please use Events Management > Events Duplicate page to add a year for this event. You can also add a year by updating 'Future interest status' field in the Overview section of this page.">
                  <Button startIcon={<OpenInNew />} onClick={() => navigate(`/${ROUTING_CONFIG.events}?initialNodeId=${EventsManagementTreeViewNodeIdEnum.eventsDuplicate}`)}>
                    Duplicate
                  </Button>
                </Tooltip>
              </Box>
            </Select>
          )}
        />
      )}
    >
      {modalView}

      <Box display="flex" flexDirection="column" gap={5} pt={5} pb={10}>
        <SectionCardsEditContextProvider>
          <Container maxWidth="lg">
            <Box maxWidth="800px" width="100%" display="flex" flexDirection="column" gap={5}>
              {!isSectionHidden(EventPageSection.OVERVIEW) && <OverviewSection />}
              {!isSectionHidden(EventPageSection.ASSIGNMENTS) && <AssignmentsSection />}
            </Box>
            {!isSectionHidden(EventPageSection.DATES) && <DatesSection />}
            <Box maxWidth="800px" width="100%" display="flex" flexDirection="column" gap={5} mt={5}>
              {!isSectionHidden(EventPageSection.TO_DOS) && <ToDosSection />}
              {!isSectionHidden(EventPageSection.PHOTOS) && <PhotosSection />}
            </Box>
          </Container>
          {!isSectionHidden(EventPageSection.SALES) && (
            <Box bgcolor="background.paper" id={EventPageSection.SALES}>
              <Box bgcolor="primary.background">
                <Divider />
                <Container maxWidth="lg">
                  <Box py={5}>
                    <SalesReport eventId={event._id} endToday={false} timePeriod={getInitialAnalyticsTimelineChartTimePeriodForDateRange({ start: DateService.dayjsTz(event.startDate?.dateAsUtc), end: DateService.dayjsTz(event.endDate?.dateAsUtc) })} />
                  </Box>
                </Container>
                <Divider />
              </Box>
            </Box>
          )}
          <Container maxWidth="lg">
            <Box maxWidth="800px" width="100%" display="flex" flexDirection="column" gap={5}>
              {!isSectionHidden(EventPageSection.APPLICATION) && <ApplicationSection />}
              {!isSectionHidden(EventPageSection.PAYMENTS) && <PaymentsSection />}
              {!isSectionHidden(EventPageSection.LOCATION) && <LocationSection />}
              {!isSectionHidden(EventPageSection.LODGING) && <LodgingSection />}
              {!isSectionHidden(EventPageSection.INSURANCE) && <InsuranceSection />}
              {!isSectionHidden(EventPageSection.LINKS) && <LinksSection />}
              {!isSectionHidden(EventPageSection.FILES) && <FilesSection />}
            </Box>
          </Container>
        </SectionCardsEditContextProvider>
      </Box>

    </PageContentContainer>
  );
};
