import { authAxiosRequest } from 'api/axios';
import { EventOrganizer, EventOrganizerContact, EventPaymentTypeEnum, User } from 'api/resources';
import { CreateResourceResponse, DeepOptionalToNull, PickFromResource, ResourceWithPopulated } from 'api/resources/resources.types';

export type GetEventOrganizersResponse = {
  data: ResourceWithPopulated<EventOrganizer, {
    createdBy?: PickFromResource<User, 'name'>;
  }>[];
};

export const getEventOrganizers = () => {
  return authAxiosRequest<GetEventOrganizersResponse>('/api/event-organizers');
};

export type GetEventOrganizerResponse = {
  data: ResourceWithPopulated<EventOrganizer, {
    createdBy?: PickFromResource<User, 'name'>;
    contacts: EventOrganizerContact[];
    salesRevenue: number;
    salesRank: number;
  }>;
};

export const getEventOrganizer = (id: string) => {
  return authAxiosRequest<GetEventOrganizerResponse>(`/api/event-organizers/${id}`);
};

export type CreateEventOrganizerInput = DeepOptionalToNull<{
  name: string;
  website: string;
  mailingAddress: string;
  defaultPaymentType?: EventPaymentTypeEnum;
  notes?: string;
}>;

export const createEventOrganizer = (data: CreateEventOrganizerInput) => {
  return authAxiosRequest<CreateResourceResponse>('/api/event-organizers', { method: 'POST', data });
};

export type UpdateEventOrganizerInput = Partial<CreateEventOrganizerInput>;

export const updateEventOrganizer = (id: string, data: UpdateEventOrganizerInput) => {
  return authAxiosRequest(`/api/event-organizers/${id}`, { method: 'PUT', data });
};

export const deleteEventOrganizer = (id: string) => {
  return authAxiosRequest(`/api/event-organizers/${id}`, { method: 'DELETE' });
};

// Contacts

export type CreateEventOrganizerContactInput = DeepOptionalToNull<{
  name: string;
  title: string;
  phone?: string;
  email?: string;
  extension?: string;
  note?: string;
}>;

export const createEventOrganizerContact = (eventOrganizerId: string, data: CreateEventOrganizerContactInput) => {
  return authAxiosRequest<CreateResourceResponse>(`/api/event-organizers/${eventOrganizerId}/contact`, { method: 'POST', data });
};

export type UpdateEventOrganizerContactInput = Partial<CreateEventOrganizerContactInput>;

export const updateEventOrganizerContact = (eventOrganizerId: string, id: string, data: UpdateEventOrganizerContactInput) => {
  return authAxiosRequest(`/api/event-organizers/${eventOrganizerId}/contact/${id}`, { method: 'PUT', data });
};

export const deleteEventOrganizerContact = (eventOrganizerId: string, id: string) => {
  return authAxiosRequest(`/api/event-organizers/${eventOrganizerId}/contact/${id}`, { method: 'DELETE' });
};