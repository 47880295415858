import { GetEventsResponse } from 'api/actions';
import { EventPreperationAcceptanceStatusEnum } from 'api/resources';
import { DateObjectType, DateService } from 'services';

export const getEventUnpaidPaymentsDue = (event: GetEventsResponse['data'][number]) => {
  return event.paymentsDue.filter(paymentDue => !paymentDue.isPaidInFull);
};

export const getEventClosestDue = (event: GetEventsResponse['data'][number]) => {
  const unpaidPaymentsDue = getEventUnpaidPaymentsDue(event);
  const asapDue = unpaidPaymentsDue.find(paymentDue => paymentDue.dueAsSoonAsPossible);
  const withApplicationDue = unpaidPaymentsDue.find(paymentDue => paymentDue.dueWithApplication);

  if (withApplicationDue) {
    return { paymentDue: withApplicationDue, daysUntilDue: 0, dueType: 'withApplication' };
  }
  if (asapDue) {
    return { paymentDue: asapDue, daysUntilDue: 0, dueType: 'asap' };
  }

  const closestDue = unpaidPaymentsDue.reduce((r, paymentDue) => {
    if (!paymentDue.dueDateAsUtc) {
      return r;
    }

    const dueDate = DateService.dayjsTz(paymentDue.dueDateAsUtc);

    if (!r.dueDate || dueDate.isBefore(r.dueDate)) {
      return { dueDate, paymentDue };
    }

    return r;
  }, { dueDate: null, paymentDue: null } as { dueDate: DateObjectType | null; paymentDue: GetEventsResponse['data'][number]['paymentsDue'][number] | null });

  const daysUntilDue = -DateService.dayjs().startOf('day').diff(closestDue.dueDate, 'days');

  return { ...closestDue, daysUntilDue, dueType: closestDue.paymentDue ? 'date' : null };
};

export const getEventOwed = (event: GetEventsResponse['data'][number]) => {
  if (event.acceptanceStatus === EventPreperationAcceptanceStatusEnum.accepted) {
    return event.cost ? event.cost - (event.paid ?? 0) : null;
  }

  const paymentDueWithApplication = event.paymentsDue.find(paymentDue => !paymentDue.isPaidInFull && paymentDue.dueWithApplication);
  const amountPaid = event.applicationPayments.reduce((r, applicationPayment) => applicationPayment.paymentDue === paymentDueWithApplication?._id ? r + applicationPayment.amount : r, 0);

  if (paymentDueWithApplication) {
    return (paymentDueWithApplication?.amountDue ?? 0) - amountPaid;
  }

  return null;
};