import { MoveToInboxOutlined, OutboxOutlined } from '@mui/icons-material';
import { Box, Card, CardContent, Chip, Divider, Stack, ToggleButton, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CreateInventoryBatchErrorResponse, CreateInventoryBatchInput, GetInventoryBatchesResponse, GetTeamsResponse, createInventoryBatch } from 'api/actions';
import { InventoryBatchStatusEnum, InventoryBatchTypeEnum } from 'api/resources';
import { BaselineChip, CardContentGrid, CardContentLink, ConfirmActionModal, ForbiddenModal, InventoryBatchStatusCell, ProgressBar, ToggleButtonGroup, UpdateResourceModal, useAlertSnackbar } from 'components';
import { ROUTING_CONFIG } from 'constants/routing-config';
import { useProductsPageContext } from 'contexts';
import { Formik } from 'formik';
import { getProductTeamBaseline, inventoryBatchEnumHelpers } from 'helpers';
import { useUpcomingEventsForTeamsHash } from 'queries';
import { QUERY_KEY } from 'queries/query-keys';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formSx } from 'styles';
import { PickingBaselineEnum } from 'types';
import { mixed, object } from 'yup';

export type CreateBatchCardsProps = {
  inventoryBatches: GetInventoryBatchesResponse['data'];
};

export type TeamsPickingCard= {
  itemsToPick: number;
  totalInStock: number;
  totalBaseline: number;
  totalOverstocked: number;
};

export const CreateBatchCards: React.FC<CreateBatchCardsProps> = (props) => {
  const navigate = useNavigate();
  const [ selectedTeam, setSelectedTeam ] = useState< GetTeamsResponse['data'][number] | null>(null);
  const snackbar = useAlertSnackbar();
  const queryClient = useQueryClient();
  const createMutation = useMutation({
    mutationFn: async (input: CreateInventoryBatchInput) => {
      return createInventoryBatch(input);
    },
    onSuccess: async (res) => {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEY.INVENTORY_BATCHES });
      snackbar.success('Batch created successfully');
      navigate(res.data._id);
    },
    onError: async (error: CreateInventoryBatchErrorResponse) => {
      if (error.response && (
        error.response.data.errorKey === 'sameTeamPickingBatchExists' || error.response.data.errorKey === 'teamIsPaused'
      )) {
        snackbar.error(error.response.data.error);
      } else {
        snackbar.error('Failed to create batch');
      }

      setSelectedTeam(null);
    }
  });
  const { data: upcomingEventsForTeamsHash = {}, isInitialLoading: upcomingEventsForTeamsHashLoading } = useUpcomingEventsForTeamsHash();
  const { teams, products } = useProductsPageContext();

  const warehouseTeam = useMemo(() => teams.find(team => team.isWarehouse), [ teams ]);
  const confirmModal = useMemo(() => {
    if (selectedTeam) {
      if (!selectedTeam.isWarehouse && props.inventoryBatches.some((batch) => batch.team._id === selectedTeam._id && ![ InventoryBatchStatusEnum.canceled, InventoryBatchStatusEnum.closed ].includes(batch.status))) {
        return (
          <ForbiddenModal
            title="Cannot create a new picking batch"
            description={`A picking batch already exists for ${selectedTeam.name}. Only one picking batch per team is allowed at a time.`}
            onClose={() => setSelectedTeam(null)}
          />
        );
      }

      if(selectedTeam.isWarehouse) {
        return (
          <ConfirmActionModal
            open
            title="Create new production batch?"
            text={`Are you sure you want to create a new production batch for ${selectedTeam.name}?`}
            onClose={() => setSelectedTeam(null)}
            onConfirm={() => createMutation.mutate({ team: selectedTeam._id })}
            confirmButtonText="Create Batch"
            loading={createMutation.isLoading}
          />
        );
      }

      const initialValues = {
        type: InventoryBatchTypeEnum.picking,
      };

      return (
        <Formik
          onSubmit={(values) => createMutation.mutateAsync({ team: selectedTeam._id, type: values.type === InventoryBatchTypeEnum.overstockPicking ? values.type : undefined })}
          initialValues={initialValues}
          validationSchema={object({ type: mixed().oneOf([ InventoryBatchTypeEnum.picking, InventoryBatchTypeEnum.overstockPicking ]).required() })}
        >
          {(formik) => {
            return (
              <UpdateResourceModal
                open
                onClose={() => setSelectedTeam(null)}
                onSave={formik.handleSubmit}
                title="Select inventory batch type"
                loading={formik.isSubmitting}
              >
                <Box sx={formSx.formGroup}>
                  <ToggleButtonGroup
                    name="type"
                    color="primary"
                    exclusive
                    fullWidth
                    size="small"
                    label={`Please, select a type for a new picking batch for ${selectedTeam.name}:`}
                  >
                    <ToggleButton value={InventoryBatchTypeEnum.picking}><MoveToInboxOutlined />&nbsp;Picking stock</ToggleButton>
                    <ToggleButton value={InventoryBatchTypeEnum.overstockPicking}><OutboxOutlined />&nbsp;Picking out overstocked</ToggleButton>
                  </ToggleButtonGroup>
                  <Typography color="text.secondary">By clicking <b>Save</b>, you confirm creating a batch.</Typography>
                </Box>
              </UpdateResourceModal>
            );
          }}
        </Formik>
      );
    }
  }, [ createMutation, props.inventoryBatches, selectedTeam ]);

  return (
    <Box display="grid" gap={1} gridTemplateColumns="repeat(auto-fill, minmax(320px, 1fr))">
      {confirmModal}
      {teams.map((team) => {
        const { needToPick, availableToPick, inStock, baseline, overstocked } = products.reduce((acc, product) => {
          const stock = product.stocks[team._id];
          const warehouseStock = warehouseTeam ? product.stocks[warehouseTeam._id] : undefined;
          const baseline = getProductTeamBaseline(product, team);
          const needToPick = Math.max(baseline - stock.quantity, 0);

          return {
            needToPick: acc.needToPick + needToPick,
            availableToPick: warehouseStock ? acc.availableToPick + Math.min(warehouseStock.quantity, needToPick) : undefined,
            inStock: acc.inStock + stock.quantity,
            baseline: acc.baseline + baseline,
            overstocked: acc.overstocked + Math.max(stock.quantity - baseline, 0)
          };
        }, {
          needToPick: 0,
          availableToPick: 0,
          inStock: 0,
          baseline: 0,
          overstocked: 0,
        });

        const TypeIcon = inventoryBatchEnumHelpers.type.getIcon(team.isWarehouse ? InventoryBatchTypeEnum.production : InventoryBatchTypeEnum.picking);
        const currentEvent = upcomingEventsForTeamsHash[team._id]?.[0];
        const currentBatch = props.inventoryBatches.find((batch) => batch.team._id === team._id && ![ InventoryBatchStatusEnum.canceled, InventoryBatchStatusEnum.closed ].includes(batch.status));
        const CurrentBatchTypeIcon = inventoryBatchEnumHelpers.type.getIcon(currentBatch?.type);

        return (
          <Card
            key={team._id}
            sx={{
              width: '100%',
              cursor: team.pauseInventory ? 'not-allowed' : 'pointer',
              bgcolor: team.pauseInventory ? 'grey.100' : undefined }}
            variant="outlined"
            onClick={() => !team.pauseInventory && setSelectedTeam(team)}
          >
            <CardContent>
              <Stack gap={2}>
                <Box display="flex" gap={2} justifyContent="space-between" alignItems="center">
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography fontWeight={500}>{team.name}</Typography>
                    <TypeIcon fontSize="small" />
                  </Box>
                  {team.pauseInventory ? (
                    <Chip label="Paused" color="info" size="small" variant="outlined" sx={{ borderRadius: 0, bgcolor: 'info.background' }} />
                  ) : (
                    <BaselineChip value={needToPick ? PickingBaselineEnum.below : PickingBaselineEnum.met} />
                  )}
                </Box>
                <Divider />
                {!team.isWarehouse && <Box>
                  <Box display="flex" justifyContent="space-between" mb={1}>
                    <Typography variant="body2">Available to pick: <Box component="span" fontWeight={700}>{availableToPick}</Box></Typography>
                    <Typography variant="body2">Need to pick: <Box component="span" fontWeight={700}>{needToPick}</Box></Typography>
                  </Box>
                  <ProgressBar currentAmount={availableToPick ?? 0} expectedAmount={needToPick} allowOverflow />
                </Box>}
                <Box>
                  <Box display="flex" justifyContent="space-between" mb={1}>
                    <Box display="flex" alignItems="flex-start">
                      <Typography variant="body2">In Stock: <Box component="span" fontWeight={700}>{inStock}</Box></Typography>
                      {!!overstocked && <Typography variant="caption"><Box component="span" fontWeight={700} color="error.main">-{overstocked}</Box> overstocked</Typography>}
                    </Box>
                    <Typography variant="body2">Baseline: <Box component="span" fontWeight={700}>{baseline}</Box></Typography>
                  </Box>
                  <ProgressBar currentAmount={inStock - overstocked} expectedAmount={baseline} allowOverflow />
                </Box>
                {team.isWarehouse && <Typography variant="body2" height="60px">Need to produce: <Box component="span" fontWeight={700}>{needToPick}</Box></Typography>}
                <CardContentGrid
                  items={[ {
                    title: 'Active Batch',
                    type: 'full',
                    value: currentBatch ?
                      (
                        <Box display="flex" alignItems="center" gap={1}>
                          <CardContentLink
                            href={`/${ROUTING_CONFIG.inventoryBatches}/${currentBatch._id}`}
                            title={currentBatch.name}
                            onClick={e => e.stopPropagation()}
                          />
                          <Box display="flex" alignItems="center" gap={0.5}>
                            <Chip
                              label={inventoryBatchEnumHelpers.type.getLabel(currentBatch.type)}
                              size="small"
                              icon={CurrentBatchTypeIcon ? <CurrentBatchTypeIcon /> : undefined}
                              variant="outlined"
                              sx={{ fontWeight: 400, borderRadius: 0 }}
                            />
                            <InventoryBatchStatusCell value={currentBatch.status} />
                          </Box>
                        </Box>
                      ) : 'No active batch'
                  } ]}
                />
                {!team.isWarehouse && (
                  <CardContentGrid
                    items={[ {
                      title: `${currentEvent?.type === 'upcoming' ? 'Upcoming' : 'Current'} Event`,
                      type: 'full',
                      loading: upcomingEventsForTeamsHashLoading,
                      value: currentEvent ? <CardContentLink href={`/events/${currentEvent.event._id}`} title={currentEvent.event.name} onClick={e => e.stopPropagation()} /> : 'No upcoming event'
                    } ]}
                  />
                )}
              </Stack>
            </CardContent>
          </Card>
        );
      })}
    </Box>
  );
};