import { EventDateTimePeriodType, StrictForm, UnknownEnum, YesNoEnum } from 'types';
import { Event, EventCommunicationLog, EventFutureInterestStatusEnum, EventIndoorOurdoorEnum, EventInsurance, EventLodgingBookingStatusEnum, EventPreperationAcceptanceStatusEnum, EventPreperationApplicationPlatformEnum, EventPreperationApplicationStatusEnum, EventPreperationParticipationStatusEnum, EventPayment, EventElectricityChoiceEnum, EventPaymentTypeEnum } from 'api/resources';
import { FileFormType } from 'components';

export type EventDateTimePeriodOptionType = EventDateTimePeriodType | 'all';

export const enum EventPageSection {
  OVERVIEW = 'overview',
  ASSIGNMENTS = 'assignments',
  TO_DOS = 'to_dos',
  DATES = 'dates',
  PHOTOS = 'photos',
  SALES = 'sales',
  APPLICATION = 'application',
  PAYMENTS = 'payments',
  LOCATION = 'location',
  LODGING = 'lodging',
  INSURANCE = 'insurance',
  LINKS = 'links',
  FILES = 'files',
}

export const enum EventPageSectionRowId {
  // OVERVIEW
  NAME = 'name',
  EVENT_ORGANIZER = 'event_organizer',
  ATTENDANCE = 'attendance',
  VENDORS = 'vendors',
  INDOOR_OUTDOOR = 'indoor_outdoor',
  RAIN_OR_SHINE = 'rain_or_shine',

  // ASSIGNMENTS
  EVENT_MANAGER = 'event_manager',
  TEAM = 'team', // includes Team Manager
  VEHICLE = 'vehicle',
  SPACE_SIZE = 'space_size',
  SPACE_NUMBER = 'space_number',
  BOOTH_REQUIREMENTS = 'booth_requirements',
  ELECTRICITY = 'electricity',
  NOTES = 'notes',
  ON_WEBSITE = 'on_website',

  // TO_DOS
  TO_DO = 'to_do',

  // APPLICATIONS
  PARTICIPATION_STATUS = 'participation_status',
  APPLICATION_DATE_RANGE = 'application_date_range',
  APPLICATION_STATUS = 'application_status',
  APPLICATION_PLATFORM = 'application_platform',
  ACCEPTANCE_STATUS = 'acceptance_status',
  APPLICATION_DENIED_REASON = 'application_denied_reason',
  COMMUNICATION_LOG = 'communication_log',
  FUTURE_INTEREST_STATUS = 'future_interest_status',

  // PAYMENTS
  JURY_FEE = 'jury_fee',
  BOOTH_FEE = 'booth_fee',
  ELECTRICITY_FEE = 'electricity_fee',
  PAYMENTS_DUE = 'payments_due',
  PAYMENTS = 'payments',
  PAYMENT_NOTE = 'payment_note',
  DEFAULT_PAYMENT_TYPE = 'default_payment_type',

  // LOCATION
  ADDRESS = 'address',

  // LODGING
  LODGING_REQUIRED = 'lodging_required',
  LODGING_PLACE = 'lodging_place',
  LODGING_NOTES = 'lodging_notes',

  // INSURANCE
  INSURANCE = 'insurance',

  // LINKS
  EXTERNAL_LINKS='external_links',

  // FILES
  APPLICATION_FILES = 'application_files',
  LODGING_FILES = 'lodging_files',
  INSURANCE_FILES = 'insurance_files',
  OTHER_FILES = 'other_files',
}

export type EventSectionRowForm = {
  // OVERVIEW
  [EventPageSectionRowId.NAME]: StrictForm<{
    name: string;
  }>;
  [EventPageSectionRowId.EVENT_ORGANIZER]: StrictForm<{
    eventOrganizer: string | null;
  }>;
  [EventPageSectionRowId.ATTENDANCE]: StrictForm<{
    attendance: number;
  }>;
  [EventPageSectionRowId.VENDORS]: StrictForm<{
    vendors: number;
  }>;
  [EventPageSectionRowId.INDOOR_OUTDOOR]: StrictForm<{
    indoorOutdoor: EventIndoorOurdoorEnum | UnknownEnum;
  }>;
  [EventPageSectionRowId.RAIN_OR_SHINE]: StrictForm<{
    rainOrShine: YesNoEnum | UnknownEnum;
  }>;

  // ASSIGNMENTS
  [EventPageSectionRowId.EVENT_MANAGER]: StrictForm<{
    eventManager: string | null;
  }>;
  [EventPageSectionRowId.TEAM]: StrictForm<{
    team: string | null;
    teamManager: string | null;
  }>;
  [EventPageSectionRowId.VEHICLE]: StrictForm<{
    vehicle: string | null;
  }>;
  [EventPageSectionRowId.SPACE_SIZE]: StrictForm<{
    spaceSize: string;
  }>;
  [EventPageSectionRowId.SPACE_NUMBER]: StrictForm<{
    spaceNumber: string;
  }>;
  [EventPageSectionRowId.BOOTH_REQUIREMENTS]: StrictForm<{
    hasRequirements: YesNoEnum | UnknownEnum;
    areTentsAllowed: YesNoEnum | UnknownEnum;

    isWhiteTopRequired: YesNoEnum | UnknownEnum;
    isFlameRetardantRequired: YesNoEnum | UnknownEnum;

    maximumHeight: number;
    isTrussAllowed: YesNoEnum | UnknownEnum;

    notes: string;
  }>;
  [EventPageSectionRowId.ELECTRICITY]: StrictForm<{
    electricityWanted: YesNoEnum | UnknownEnum;
    electricityAvailable: YesNoEnum | UnknownEnum;
    electricityFeeRequired: YesNoEnum | UnknownEnum;
    electricGeneratorAllowed: YesNoEnum | UnknownEnum;
    electricityChoice: EventElectricityChoiceEnum | UnknownEnum;
  }>;
  [EventPageSectionRowId.NOTES]: StrictForm<{
    notes: string;
  }>;
  [EventPageSectionRowId.ON_WEBSITE]: StrictForm<{
    onWebsite: YesNoEnum | UnknownEnum;
  }>;

  [EventPageSectionRowId.TO_DO]: StrictForm<{
    title: string;
    description: string;
    date: string | null;
    assignees: string[];
  }>;

  // Application
  [EventPageSectionRowId.PARTICIPATION_STATUS]: StrictForm<{
    participationStatus: EventPreperationParticipationStatusEnum | UnknownEnum;
  }>;
  [EventPageSectionRowId.APPLICATION_DATE_RANGE]: StrictForm<{
    applicationOpenDate: string | null;
    applicationIsOpen: boolean;
    applicationDeadlineDate: string | null;
    applicationOpenUntilFull: boolean;
  }>;
  [EventPageSectionRowId.APPLICATION_STATUS]: StrictForm<{
    applicationStatus: EventPreperationApplicationStatusEnum | UnknownEnum;
  }>;
  [EventPageSectionRowId.APPLICATION_PLATFORM]: StrictForm<{
    applicationPlatform: EventPreperationApplicationPlatformEnum | UnknownEnum;
  }>;
  [EventPageSectionRowId.ACCEPTANCE_STATUS]: StrictForm<{
    acceptanceStatus: EventPreperationAcceptanceStatusEnum | UnknownEnum;
  }>;
  [EventPageSectionRowId.APPLICATION_DENIED_REASON]: StrictForm<{
    applicationDeniedReason: string;
  }>;
  [EventPageSectionRowId.COMMUNICATION_LOG]: StrictForm<{
    date: string;
    type: EventCommunicationLog['type'];
    note: string;
  }>;
  [EventPageSectionRowId.FUTURE_INTEREST_STATUS]: StrictForm<{
    futureInterestStatus: EventFutureInterestStatusEnum | UnknownEnum;
  }>;

  // Payments
  [EventPageSectionRowId.JURY_FEE]: StrictForm<{
    juryFeeRequired: YesNoEnum | UnknownEnum;
    juryFeeAmount: number;
    juryFeePaid: YesNoEnum | UnknownEnum;
    juryFeePaymentType: Event['juryFeePaymentType'] | UnknownEnum;
  }>;
  [EventPageSectionRowId.BOOTH_FEE]: StrictForm<{
    boothFee: number;
  }>;
  [EventPageSectionRowId.ELECTRICITY_FEE]: StrictForm<{
    electricityFeeAmount: number;
  }>;
  [EventPageSectionRowId.PAYMENTS_DUE]: StrictForm<{
    amountDue: number;
    dueWithApplication?: boolean;
    dueAsSoonAsPossible?: boolean;
    dueDate?: string;
  }>;
  [EventPageSectionRowId.PAYMENTS]: StrictForm<{
    receiptNumber: string;
    amount: string;
    type: EventPayment['type'] | UnknownEnum;
    paymentDue: string;
  }>;
  [EventPageSectionRowId.DEFAULT_PAYMENT_TYPE]: StrictForm<{
    defaultPaymentType: EventPaymentTypeEnum | UnknownEnum;
  }>;
  [EventPageSectionRowId.PAYMENT_NOTE]: StrictForm<{
    paymentNote: string;
  }>;


  [EventPageSectionRowId.ADDRESS]: StrictForm<{
    location: string;
    placeId: string;
  }>;

  [EventPageSectionRowId.LODGING_REQUIRED]: StrictForm<{
    isRequired: YesNoEnum | UnknownEnum;
  }>;
  [EventPageSectionRowId.LODGING_PLACE]: StrictForm<{
    bookingStatus: EventLodgingBookingStatusEnum | UnknownEnum;
    cost: number;
    finalCost: number;

    placeId: string;
    placeName: string;
    placeAddress: string;

    notes: string;
    checkIn: string | null;
    checkOut: string | null;
    website: string;
    phone: string;
    confirmationNumber: string;
    needToSendCreditAuthorization: YesNoEnum | UnknownEnum;
    creditAuthorizationSent: YesNoEnum | UnknownEnum;
  }>;
  [EventPageSectionRowId.LODGING_NOTES]: StrictForm<{
    notes: string;
  }>;

  [EventPageSectionRowId.INSURANCE]: StrictForm<{
    isRequired: YesNoEnum | UnknownEnum;
    status: EventInsurance['status'] | UnknownEnum;
    shared: EventInsurance['shared'] | UnknownEnum;
  }>;

  [EventPageSectionRowId.EXTERNAL_LINKS]: StrictForm<{
    title: string;
    url: string;
  }>;

  [EventPageSectionRowId.APPLICATION_FILES]: FileFormType;
  [EventPageSectionRowId.LODGING_FILES]: FileFormType;
  [EventPageSectionRowId.INSURANCE_FILES]: FileFormType;
  [EventPageSectionRowId.OTHER_FILES]: FileFormType;
};