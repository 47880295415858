import { updateProduct } from 'api/actions';
import { SectionCard, RadioGroupYesNoBoolean } from 'components';
import { processFormValueUpdate } from 'helpers';
import React from 'react';
import { yesNoUnknownSchema } from 'types';
import { object } from 'yup';
import { useProductOutletContext } from '../Product.base';
import { ProductPageSectionCardRowYesNoUnknownContent } from '../components/ProductPageSectionCardRowYesNoUnknownContent.component';
import { ProductSectionCardRowEditable } from '../components/ProductSectionCardRow.component';
import { ProductPageSection, ProductPageSectionRowId } from '../types';
import { Box } from '@mui/material';
import { formSx } from 'styles';
import { yesNoEnumHelpers } from 'helpers/enums/yes-no-enum.helpers';

export const AvailabilitySection = () => {
  const { product } = useProductOutletContext();

  return (
    <SectionCard title="Availability" id={ProductPageSection.AVAILABILITY}>
      <ProductSectionCardRowEditable
        title="At Events"
        rowId={ProductPageSectionRowId.EVENTS_AVAILABILITY}
        formikProps={{
          initialValues: { events: yesNoEnumHelpers.yesNo.getEnumValue(product.availability?.events)  },
          onSubmit: (values) => updateProduct(product._id, { availability: { events: processFormValueUpdate.yesNoUnknown(values.events) } }),
          validationSchema: object({ events: yesNoUnknownSchema })
        }}
        form={<RadioGroupYesNoBoolean name="events" label="Is product available at events?" row includeUnknown />}
      >
        <ProductPageSectionCardRowYesNoUnknownContent value={product.availability?.events} text="Available" />
      </ProductSectionCardRowEditable>
      <ProductSectionCardRowEditable
        title="On Website"
        rowId={ProductPageSectionRowId.WEBSITE_AVAILABILITY}
        formikProps={{
          initialValues: { website: yesNoEnumHelpers.yesNo.getEnumValue(product.availability?.website), uploaded: yesNoEnumHelpers.yesNo.getEnumValue(product.availability?.uploaded) },
          onSubmit: (values) => updateProduct(product._id, { availability: { website: processFormValueUpdate.yesNoUnknown(values.website), uploaded: processFormValueUpdate.yesNoUnknown(values.uploaded) } }),
          validationSchema: object({ website: yesNoUnknownSchema, uploaded: yesNoUnknownSchema })
        }}
        form={(
          <Box sx={formSx.formGroup}>
            <RadioGroupYesNoBoolean name="website" label="Is product available on website?" row includeUnknown />
            <RadioGroupYesNoBoolean name="uploaded" label="Is product uploaded to website?" row includeUnknown />
          </Box>
        )}
      >
        <Box display="flex">
          <Box flex={1}>
            <ProductPageSectionCardRowYesNoUnknownContent value={product.availability?.website} text="Available" />
          </Box>
          <Box flex={1}>
            <ProductPageSectionCardRowYesNoUnknownContent value={product.availability?.uploaded} text="Uploaded" />
          </Box>
        </Box>
      </ProductSectionCardRowEditable>
    </SectionCard>
  );
};
