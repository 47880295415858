import { ResouceWarningConfig } from '../types';
import { currencyFormatter } from 'services';

import { getWarningsForResource } from '../resource-warnings';
import { GetEventResponse, GetEventsResponse } from 'api/actions';
import { EventPreperationAcceptanceStatusEnum, EventPreperationApplicationStatusEnum, EventPreperationParticipationStatusEnum } from 'api/resources';
import { EventPageSection, EventPageSectionRowId } from 'components/Event/types';

export const eventWarningConfigs: ResouceWarningConfig<GetEventsResponse['data'][number], EventPageSection, EventPageSectionRowId>[] = [
  {
    section: EventPageSection.OVERVIEW,
    row: EventPageSectionRowId.EVENT_ORGANIZER,
    needsAttention: (event) => {
      if (!event.eventOrganizer) {
        return 'Event Organizer is not assigned';
      }

      return false;
    }
  },
  {
    section: EventPageSection.OVERVIEW,
    row: EventPageSectionRowId.TEAM,
    needsAttention: (event) => {
      if (!event.team) {
        return 'Team is not assigned';
      }

      return false;
    }
  },
  {
    section: EventPageSection.OVERVIEW,
    row: EventPageSectionRowId.EVENT_MANAGER,
    needsAttention: (event) => {
      if (!event.eventManager) {
        return 'Event manager is not assigned';
      }

      return false;
    }
  },
  {
    section: EventPageSection.OVERVIEW,
    row: EventPageSectionRowId.ATTENDANCE,
    needsAttention: (event) => {
      if (!event.attendance) {
        return 'Attendance number is not assigned';
      }

      return false;
    }
  },
  {
    section: EventPageSection.OVERVIEW,
    row: EventPageSectionRowId.SPACE_SIZE,
    needsAttention: (event) => {
      if (!event.spaceSize) {
        return 'Space Size is not assigned';
      }

      return false;
    }
  },
  {
    section: EventPageSection.OVERVIEW,
    row: EventPageSectionRowId.SPACE_NUMBER,
    needsAttention: (event) => {
      if (!event.spaceNumber) {
        return 'Space Number is not assigned';
      }

      return false;
    }
  },
  {
    section: EventPageSection.OVERVIEW,
    row: EventPageSectionRowId.VENDORS,
    needsAttention: (event) => {
      if (!event.vendors) {
        return 'Vendors number is not assigned';
      }

      return false;
    }
  },
  {
    section: EventPageSection.OVERVIEW,
    row: EventPageSectionRowId.INDOOR_OUTDOOR,
    needsAttention: (event) => {
      if (!event.indoorOutdoor) {
        return 'Unknown if event is indoor or outdoor';
      }

      return false;
    }
  },
  {
    section: EventPageSection.OVERVIEW,
    row: EventPageSectionRowId.RAIN_OR_SHINE,
    needsAttention: (event) => {
      if (event.rainOrShine === undefined) {
        return 'Unknown if event is \'Rain or Shine\'';
      }

      return false;
    }
  },
  {
    section: EventPageSection.OVERVIEW,
    row: EventPageSectionRowId.ON_WEBSITE,
    needsAttention: (event) => {
      if (event.participationStatus === EventPreperationParticipationStatusEnum.participating) {
        if (event.onWebsite === undefined) {
          return 'It\'s unknown if event was added to the website';
        }
        if (event.onWebsite === false) {
          return 'Event was not added to the website';
        }
      }

      return false;
    }
  },
  {
    section: EventPageSection.OVERVIEW,
    row: EventPageSectionRowId.ELECTRICITY,
    needsAttention: (event) => {
      const {
        electricGeneratorAllowed,
        electricityAvailable,
        electricityWanted,
      } = event;
      const description: string[] = [];

      if (electricityWanted === undefined) {
        description.push('Unknown if electricity is wanted');
      }
      if (electricityWanted && electricityAvailable === undefined) {
        description.push('Unknown if electricity is available');
      }
      if (electricityWanted && electricGeneratorAllowed === undefined) {
        description.push('Unknown if electric generator is allowed');
      }

      return description.length ? description : false;
    }
  },
  {
    section: EventPageSection.APPLICATION,
    row: EventPageSectionRowId.APPLICATION_DATE_RANGE,
    needsAttention: (event) => {
      const description: string[] = [];

      if (!event.applicationIsOpen && !event.applicationOpenDate) {
        description.push('Application is neither marked open nor assigned open date');
      }
      if (!event.applicationOpenUntilFull && !event.applicationDeadlineDate) {
        description.push('Application is neither marked open until full nor assigned deadline date');
      }

      return description.length ? description : false;
    }
  },
  {
    section: EventPageSection.APPLICATION,
    row: EventPageSectionRowId.APPLICATION_STATUS,
    needsAttention: (event) => {
      if (event.participationStatus === EventPreperationParticipationStatusEnum.interested && event.applicationStatus !== EventPreperationApplicationStatusEnum.applied) {
        return 'Haven\'t applied for the event yet';
      }

      return false;
    }
  },
  {
    section: EventPageSection.APPLICATION,
    row: EventPageSectionRowId.APPLICATION_PLATFORM,
    needsAttention: (event) => {
      if (!event.applicationPlatform) {
        return 'Application platform is not assigned';
      }

      return false;
    }
  },
  {
    section: EventPageSection.APPLICATION,
    row: EventPageSectionRowId.ACCEPTANCE_STATUS,
    needsAttention: (event) => {
      if (event.participationStatus === EventPreperationParticipationStatusEnum.interested) {
        if(event.acceptanceStatus === EventPreperationAcceptanceStatusEnum.followUp) {
          return 'Need to follow up on the application';
        }
        if(event.acceptanceStatus === EventPreperationAcceptanceStatusEnum.waitListPending) {
          return 'Application is waitlisted';
        }
      }

      return false;
    }
  },
  {
    section: EventPageSection.LOCATION,
    row: EventPageSectionRowId.VEHICLE,
    needsAttention: (event) => {
      if (!event.vehicle) {
        return 'Vehicle is not assigned';
      }

      return false;
    }
  },
  {
    section: EventPageSection.LODGING,
    row: EventPageSectionRowId.LODGING_REQUIRED,
    needsAttention: (event) => {
      if (event.lodging?.isRequired === undefined) {
        return 'Unknown if lodging is required';
      }

      return false;
    }
  },
  {
    section: EventPageSection.PAYMENTS,
    row: EventPageSectionRowId.PAYMENTS,
    needsAttention: (event) => {
      if (event.cost && event.cost > event.paid) {
        return `Event application is not paid in full (${currencyFormatter.format(event.cost - event.paid)} is due)`;
      }

      return false;
    }
  },
  {
    section: EventPageSection.PAYMENTS,
    row: EventPageSectionRowId.JURY_FEE,
    needsAttention: (event) => {
      if (event.juryFee === undefined) {
        return 'It\'s unknown if jury fee is required';
      }
      if (event.juryFee && !event.juryFeeAmount) {
        return 'Jury Fee amount is not filled';
      }
      if (event.juryFee && !event.juryFeePaid) {
        return 'Jury Fee is not paid';
      }
      if (event.juryFeePaid && !event.juryFeePaymentType) {
        return 'Jury Fee payment type is unknown';
      }

      return false;
    }
  },
  {
    section: EventPageSection.PAYMENTS,
    row: EventPageSectionRowId.ELECTRICITY_FEE,
    needsAttention: (event) => {
      if (event.electricityWanted && event.electricityAvailable) {
        return false;
      }

      const {
        electricityFee,
        electricityFeeAmount,
      } = event;

      if (electricityFee === undefined) {
        return 'Unknown if there\'s an electricity fee';
      }

      if (electricityFee) {
        if(!electricityFeeAmount) {
          return 'Electricity fee amount fee is unknown';
        }
      }

      return false;
    }
  },
  {
    section: EventPageSection.INSURANCE,
    row: EventPageSectionRowId.INSURANCE,
    needsAttention: (event) => {
      if (event.insurance?.isRequired === undefined) {
        return 'It\'s unknown if insurance is required';
      }

      const description: string[] = [];

      if (event.insurance?.isRequired && !event.insurance?.status) {
        description.push('Insurance Status is \'Unknown\'');
      }
      if (event.insurance?.isRequired && !event.insurance?.shared) {
        description.push('Insurance Shared is \'Unknown\'');
      }

      return description.length ? description : false;
    }
  },
];

export const getWarningsForEvent = (event: GetEventsResponse['data'][number] | GetEventResponse['data']) => getWarningsForResource(event, eventWarningConfigs);