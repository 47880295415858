import { CardContentMarkdown, MarkdownInput, ProgressBar, SectionCard, Select, TextInput, YesNoUnknownIconSwitchWithLabel, getFormattedPaymentDue } from 'components';
import { Box, ListItem, ListItemText, Typography } from '@mui/material';
import { DateService, currencyFormatter } from 'services';
import { eventEnumHelpers, getSelectOptionsFromEnumHelper, getSortedPaymentsDue, processFormValueUpdate, removeUnchanged } from 'helpers';
import { EventElectricityChoiceEnum, EventPaymentTypeEnum } from 'api/resources';
import { createEventApplicationPayment, createEventPaymentDue, deleteEventApplicationPayment, deleteEventPaymentDue, updateEvent, updateEventApplicationPayment, updateEventPaymentDue } from 'api/actions';
import { UnknownEnum, YesNoEnum, yesNoUnknownSchema } from 'types';
import { mixed, number, object, string } from 'yup';
import { useCallback, useMemo } from 'react';
import { EventSectionCardRowEditable, EventSectionCardRowEditableList } from '../components';
import { eventApplicationPaymentValidationSchema, EventApplicationPaymentForm, JuryFeeForm, eventPaymentDueValidationSchema } from '../forms';
import { EventPageSection, EventPageSectionRowId } from '../types';
import { useEventContext } from '../event.context';
import { EventPaymentDueForm } from '../forms/EventPaymentDue.form';
import { yesNoEnumHelpers } from 'helpers/enums/yes-no-enum.helpers';

type FeeContentArgs = {
  required?: boolean;
  amount?: number;
  paid?: boolean;
  paymentTypeLabel?: string | UnknownEnum;
};

export const PaymentsSection: React.FC= () => {
  const { event } = useEventContext();
  const defaultPaymentTypeOptions = useMemo(() => getSelectOptionsFromEnumHelper(eventEnumHelpers.paymentType), []);

  const stillOwed = event.cost - event.paid;
  const youOwe = stillOwed ? `You Owe: ${currencyFormatter.format(stillOwed)}` : 'Paid in full!';

  const getFeeContent = useCallback(({ required, amount, paid, paymentTypeLabel }: FeeContentArgs) => {
    if (required) {
      const amountLabel = amount ? currencyFormatter.format(amount) : '';

      if (paid) {

        return (
          <YesNoUnknownIconSwitchWithLabel value>
            <Typography color="success.main" variant="body2">Paid</Typography>
            <Typography>
              {[ amountLabel, paymentTypeLabel ].filter(x => x).join(' ')}
            </Typography>
          </YesNoUnknownIconSwitchWithLabel>
        );
      }

      if (amount) {
        return (
          <YesNoUnknownIconSwitchWithLabel value={false}>
            <Typography color="error.main" variant="body2">Unpaid</Typography>
            <Typography>
              {amountLabel} Due
            </Typography>
          </YesNoUnknownIconSwitchWithLabel>
        );
      }

      return 'Required';

    }

    if (required === false) {
      return 'Not Required';
    }

    return null;
  }, []);

  return (
    <SectionCard title="Payments" id={EventPageSection.PAYMENTS}>
      <EventSectionCardRowEditable
        title="Jury Fee"
        rowId={EventPageSectionRowId.JURY_FEE}
        formikProps={{
          onSubmit: (values, { initialValues }) => {
            const updates = removeUnchanged(values, initialValues);

            return updateEvent(event._id, {
              juryFee: processFormValueUpdate.yesNoUnknown(updates.juryFeeRequired),
              juryFeeAmount: processFormValueUpdate.number(updates.juryFeeAmount),
              juryFeePaid: processFormValueUpdate.yesNoUnknown(updates.juryFeePaid),
              juryFeePaymentType: processFormValueUpdate.enumWithUnknown(updates.juryFeePaymentType)
            });
          },
          initialValues: {
            juryFeeRequired: yesNoEnumHelpers.yesNo.getEnumValue(event.juryFee),
            juryFeeAmount: event.juryFeeAmount ?? 0,
            juryFeePaid: yesNoEnumHelpers.yesNo.getEnumValue(event.juryFeePaid),
            juryFeePaymentType: event.juryFeePaymentType ?? event.defaultPaymentType ?? UnknownEnum.unknown,
          },
          validationSchema: object({
            juryFeeRequired: yesNoUnknownSchema,
            juryFeeAmount: number().required().when('juryFee', {
              is: (juryFee: YesNoEnum) => juryFee === YesNoEnum.yes,
              then: schema => schema.required('Jury Fee Amount Required').min(1, 'Jury Fee Amount cannot be this low'),
            }),
            juryFeePaid: yesNoUnknownSchema,
            juryFeePaymentType: mixed<EventPaymentTypeEnum | UnknownEnum>().oneOf(eventEnumHelpers.paymentType.enumValues).required(),
          })
        }}
        form={<JuryFeeForm />}
      >
        {getFeeContent({
          required: event.juryFee,
          amount: event.juryFeeAmount,
          paid: event.juryFeePaid,
          paymentTypeLabel: event.juryFeePaymentType ? eventEnumHelpers.paymentType.getLabel(event.juryFeePaymentType) : ''
        })}
      </EventSectionCardRowEditable>

      <EventSectionCardRowEditable
        title="Booth fee"
        rowId={EventPageSectionRowId.BOOTH_FEE}
        formikProps={{
          initialValues: { boothFee: event.boothFee ?? 0 },
          onSubmit: values => updateEvent(event._id, { boothFee: processFormValueUpdate.number(values.boothFee) }),
          validationSchema: object({ attendance: number().default(0) })
        }}
        form={(
          <TextInput
            fullWidth
            id="boothFee"
            name="boothFee"
            label="Booth fee"
            type="number"
            startAdornment="$"
          />
        )}
      >
        {event.boothFee}
      </EventSectionCardRowEditable>

      {event.electricityWanted && event.electricityChoice === EventElectricityChoiceEnum.offered && (
        <EventSectionCardRowEditable
          title="Electricity Fee"
          rowId={EventPageSectionRowId.ELECTRICITY_FEE}
          formikProps={{
            onSubmit: (values) => updateEvent(event._id, { electricityFeeAmount: processFormValueUpdate.number(values.electricityFeeAmount) }),
            initialValues: {
              electricityFeeAmount: event.electricityFeeAmount ?? 0,
            },
            validationSchema: object({
              electricityFeeAmount: number().required('Electricity Fee Amount Required').min(1, 'Electricity Fee Amount cannot be this low')
            })
          }}
          form={(
            <TextInput
              name="electricityFeeAmount"
              label="Electricity Fee Amount *"
              type="number"
            />
          )}
        >
          {event.electricityFeeAmount}
        </EventSectionCardRowEditable>
      )}

      <EventSectionCardRowEditableList
        title="Deadlines"
        listItems={getSortedPaymentsDue(event.paymentsDue)}
        rowId={EventPageSectionRowId.PAYMENTS_DUE}
        renderItem={(paymentDue) => {
          return (
            <ListItem components={{ Root: 'div' }} disableGutters disablePadding>
              <ListItemText
                primary={(
                  <Typography sx={{ textDecoration: paymentDue.isPaidInFull ? 'line-through' : 'none' }}>
                    {currencyFormatter.format(paymentDue.amountDue)}
                  </Typography>
                )}
                secondary={(
                  <Typography variant="body2" color="text.secondary" sx={{ textDecoration: paymentDue.isPaidInFull ? 'line-through' : 'none' }}>
                    {getFormattedPaymentDue(paymentDue)}
                  </Typography>
                )}
              />
            </ListItem>
          );
        }}
        createButtonLabel="Payment Deadline"
        getFormikProps={(payment) => ({
          initialValues: {
            amountDue: payment.amountDue,
            dueAsSoonAsPossible: payment.dueAsSoonAsPossible,
            dueWithApplication: payment.dueWithApplication,
            dueDate: payment.dueDateAsUtc,
          },
          onSubmit: async (values, { initialValues }) => {
            const updates = removeUnchanged(values, initialValues);

            await updateEventPaymentDue(event._id, payment._id, {
              amountDue: updates.amountDue,
              dueDate: updates.dueDate ? DateService.dayjs(updates.dueDate).format('YYYY-MM-DD') : null,
              dueAsSoonAsPossible: updates.dueAsSoonAsPossible,
              dueWithApplication: updates.dueWithApplication,
            });
          },
          validationSchema: eventPaymentDueValidationSchema,
        })}
        createFormikProps={{
          initialValues: {
            amountDue: 0,
            dueDate: '',
          },
          onSubmit: (form) => createEventPaymentDue(event._id, {
            amountDue: form.amountDue,
            dueDate: form.dueDate ? DateService.dayjs(form.dueDate).format('YYYY-MM-DD') : null,
            dueAsSoonAsPossible: form.dueAsSoonAsPossible,
            dueWithApplication: form.dueWithApplication,
          }),
          validationSchema: eventPaymentDueValidationSchema,
        }}
        form={<EventPaymentDueForm />}
        deleteMutationFn={(payment) => deleteEventPaymentDue(event._id, payment._id)}
      />
      <EventSectionCardRowEditableList
        title="Payments"
        listItems={event.applicationPayments}
        rowId={EventPageSectionRowId.PAYMENTS}
        renderItem={(payment) => {
          const secondary = [
            DateService.getFormattedDate(payment.createdAt, 'MMM Do, YYYY h:mma'),
            payment.receiptNumber ? `Receipt: ${payment.receiptNumber}` : null,
          ].filter(x => x).join(' • ');

          return (
            <ListItem components={{ Root: 'div' }} disableGutters disablePadding>
              <ListItemText
                primary={`${currencyFormatter.format(payment.amount)} ${eventEnumHelpers.paymentType.getLabel(payment.type)}`}
                secondary={secondary}
              />
            </ListItem>
          );
        }}
        orderBy={{
          getField: (payment) => payment.createdAt,
          direction: 'desc',
        }}
        createButtonLabel="Payment"
        createButtonDisabled={stillOwed <= 0}
        getFormikProps={(payment) => ({
          initialValues: {
            receiptNumber: payment.receiptNumber ?? '',
            amount: String(payment.amount) ?? '',
            type: payment.type,
            paymentDue: payment.paymentDue ?? '',
          },
          onSubmit: async (values, { initialValues }) => {
            const updates = removeUnchanged(values, initialValues);

            await updateEventApplicationPayment(event._id, payment._id, {
              receiptNumber: processFormValueUpdate.string(updates.receiptNumber),
              amount: updates.amount ? Number(updates.amount) : undefined,
              type: processFormValueUpdate.enumWithUnknown(updates.type),
              paymentDue: updates.paymentDue,
            });
          },
          validationSchema: eventApplicationPaymentValidationSchema(event.applicationPayments.filter(p => p._id !== payment._id), event.cost)
        })}
        createFormikProps={{
          initialValues: {
            receiptNumber: '',
            amount: '',
            type: event.defaultPaymentType ?? UnknownEnum.unknown,
            paymentDue: '',
          },
          onSubmit: (form) => createEventApplicationPayment(event._id, {
            receiptNumber: form.receiptNumber || undefined,
            amount: Number(form.amount),
            type: processFormValueUpdate.enumWithUnknown(form.type),
            paymentDue: form.paymentDue || undefined,
          }),
          validationSchema: eventApplicationPaymentValidationSchema(event.applicationPayments ?? [], event.cost)
        }}
        form={<EventApplicationPaymentForm />}
        deleteMutationFn={(payment) => deleteEventApplicationPayment(event._id, payment._id)}
      >
        <Box display="flex" gap={2} alignItems="center" whiteSpace="nowrap">
          {!!event.cost && (
            <>
              <ProgressBar currentAmount={event.paid} expectedAmount={event.cost} isCurrency />
              <Typography>{youOwe}</Typography>
            </>
          )}
        </Box>
      </EventSectionCardRowEditableList>

      <EventSectionCardRowEditable
        title="Default Payment Method"
        rowId={EventPageSectionRowId.DEFAULT_PAYMENT_TYPE}
        formikProps={{
          onSubmit: (values) => updateEvent(event._id, { defaultPaymentType: processFormValueUpdate.enumWithUnknown(values.defaultPaymentType) }),
          initialValues: { defaultPaymentType: event.defaultPaymentType ?? UnknownEnum.unknown },
          validationSchema: object({ defaultPaymentType: string().default(UnknownEnum.unknown) })
        }}
        form={(
          <Select
            id="defaultPaymentType"
            name="defaultPaymentType"
            label="Default Payment Type"
            options={defaultPaymentTypeOptions}
          />
        )}
      >
        {event.defaultPaymentType ? eventEnumHelpers.paymentType.getLabel(event.defaultPaymentType) : ''}
      </EventSectionCardRowEditable>

      <EventSectionCardRowEditable
        title="Payments Note"
        rowId={EventPageSectionRowId.PAYMENT_NOTE}
        formikProps={{
          onSubmit: (values) => updateEvent(event._id, { paymentNote: processFormValueUpdate.string(values.paymentNote) }),
          initialValues: { paymentNote: event.paymentNote ?? '' },
          validationSchema: object({ paymentNote: string().default('') })
        }}
        form={<MarkdownInput name="paymentNote" />}
        formFullWidth
      >
        <CardContentMarkdown markdown={event.paymentNote} />
      </EventSectionCardRowEditable>
    </SectionCard>
  );
};