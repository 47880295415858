import { Checkbox, FormControlLabel } from '@mui/material';
import { GridColDef, GridColumnGroup, GridValueGetterParams } from '@mui/x-data-grid';
import { ProductStock, UserEmployeeRoleEnum } from 'api/resources';
import { FilterButton, Table, TableActionsBar, EditSettingsModal, TeamSelectionInput, ShowNotAtEventsInput, TeamCell } from 'components';
import flatten from 'lodash/flatten';
import { useCurrentUser, useProductsPageContext } from 'contexts';
import { useMemo, useState } from 'react';
import { GetProductsResponse, GetTeamsResponse } from 'api/actions';
import { getWarehouseTeams, getProductTeamBaseline, getProductTotalProduceAmount, getProductProduceAmount, productSalesCountColumns } from 'helpers';
import { useTableActionColumn } from 'hooks';
import { ROUTING_CONFIG } from 'constants/routing-config';

const enum PerTeamColumnKeys {
  baseline = 'baseline',
  inStock = 'inStock',
  produce = 'produce',
}

type PerTeamColumnGetters = {
  field: string;
  getValue: (product: GetProductsResponse['data'][number], team: GetTeamsResponse['data'][number], stock: ProductStock) => string | number;
};

const perTeamColumnsHash: Record<PerTeamColumnKeys, PerTeamColumnGetters> = {
  [PerTeamColumnKeys.baseline]: { field: 'Baseline', getValue: (product, team) => team.isWarehouse ? product.activeWarehouseBaseline : product.activeBaseline },
  [PerTeamColumnKeys.inStock]: { field: 'Stock', getValue: (_, __, stock) => stock.quantity },
  [PerTeamColumnKeys.produce]: { field: 'Produce', getValue: (product, team, stock) => getProductProduceAmount(getProductTeamBaseline(product, team), stock.quantity) },
};

const perTeamColumns= Object.values(perTeamColumnsHash);

const useProductionTableColumns = (selectedTeams: GetTeamsResponse['data']): GridColDef[] => {
  const { isAuthorizedEmployee } = useCurrentUser();
  const inventoryColumns: GridColDef[] = flatten(selectedTeams.map((team) => {
    return perTeamColumns.map(({ field, getValue }): GridColDef => {
      return {
        field: `${team._id}-${field}`, // arbitrary
        headerName: field,
        flex: 1,
        width: 60,
        type: 'number',
        valueGetter: (params: GridValueGetterParams<GetProductsResponse['data'][number]>) => getValue(params.row, team, params.row.stocks[team._id]),
        editable: false,
        // hide production column from wholesale managers
        hideable: field !== perTeamColumnsHash.produce.field || isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ]) ? true : false,
      };
    });
  }));

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 130,
      hideable: false,
    }
  ];

  if (isAuthorizedEmployee([ UserEmployeeRoleEnum.wholesaleManager ])) {
    columns.push(...productSalesCountColumns);
  } else {
    columns.push(...productSalesCountColumns.map(column => ({ ...column, hideable: false })));
  }

  columns.push(
    {
      field: 'createdAt',
      headerName: 'Created At',
      valueGetter: ({ value }) => new Date(value),
      type: 'date',
      width: 150,
    },
    ...inventoryColumns,
    {
      field: 'total',
      headerName: 'Total Produce',
      width: 130,
      hideable: isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ]),
      valueGetter: (params: GridValueGetterParams<GetProductsResponse['data'][number]>) => {
        return selectedTeams.reduce((total, team) => {
          const stock = params.row.stocks[team._id];

          return total + getProductProduceAmount(getProductTeamBaseline(params.row, team), stock.quantity);
        }, 0);
      }
    },
  );

  return columns;
};

export const ProductionTable: React.FC = () => {
  const { isAuthorizedEmployee } = useCurrentUser();
  const { products, teams, loading } = useProductsPageContext();
  const [ selectedTeams, setSelectedTeams ] = useState<GetTeamsResponse['data']>(getWarehouseTeams(teams));
  const [ showMetBaseline, setShowMetBaseline ] = useState<boolean>(!isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ]));
  const [ showProductsNotAvailableAtEvents, setShowProductsNotAvailableAtEvents ] = useState<boolean>(!isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ]));
  const [ settingsOpen, setSettingsOpen ] = useState(false);

  const { withActionColumn } = useTableActionColumn({ routeTo: ROUTING_CONFIG.productList });
  const productionTableColumnsRaw = useProductionTableColumns(selectedTeams);
  const productionTableColumns = withActionColumn(productionTableColumnsRaw);

  const rows = products.filter((product) => {
    if (!showMetBaseline) {
      const metBaseline = getProductTotalProduceAmount(selectedTeams, product) === 0;

      if (metBaseline) {
        return false;
      }
    }

    if (!showProductsNotAvailableAtEvents && !product.availability?.events) {
      return false;
    }

    return true;
  });

  const columnGroupingModel = useMemo((): GridColumnGroup[] => {
    return selectedTeams.map((team) => {
      const children = perTeamColumns.map(({ field }) => ({ field: `${team._id}-${field}` }));

      return {
        groupId: team._id,
        renderHeaderGroup: () => <TeamCell team={team} />,
        headerAlign: 'center',
        children
      };
    });
  }, [ selectedTeams ]);

  return (
    <>
      <EditSettingsModal
        open={settingsOpen}
        onClose={() => setSettingsOpen(false)}
        title="Production Filters"
        teamSelectionInput={<TeamSelectionInput selectedTeams={selectedTeams} setSelectedTeams={setSelectedTeams} teams={teams} />}
        showNotAtEventsInput={<ShowNotAtEventsInput showProductsNotAvailableAtEvents={showProductsNotAvailableAtEvents} setShowProductsNotAvailableAtEvents={setShowProductsNotAvailableAtEvents} />}
        otherFilters={(
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => setShowMetBaseline((prev) => !prev)}
                checked={showMetBaseline}
              />
            }
            label="Show products that met the baseline for all teams"
          />
        )}
      />
      <TableActionsBar
        leftActions={(
          <FilterButton
            onClick={() => setSettingsOpen(true)}
            disabled={!isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ])}
          />
        )}
      />
      <Table
        rows={rows}
        columns={productionTableColumns}
        loading={loading}
        getRowId={(x) => x._id}
        disableRowSelectionOnClick
        initialState={{
          columns: {
            columnVisibilityModel: {
              salesCount: isAuthorizedEmployee([ UserEmployeeRoleEnum.wholesaleManager ]) ? true : false,
              prevSixMonthSalesCount: isAuthorizedEmployee([ UserEmployeeRoleEnum.wholesaleManager ]) ? true : false,
              total: isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ]) ? true : false,
              ...selectedTeams.reduce((r, team) => ({
                ...r,
                [`${team._id}-${perTeamColumnsHash.produce.field}`]: isAuthorizedEmployee([ UserEmployeeRoleEnum.productionManager, UserEmployeeRoleEnum.productionMember ]) ? true : false,
              }), {}),
              createdAt: false,
            }
          },
          pinnedColumns: { right: [ 'total' ] },
          sorting: {
            sortModel: [ { field: 'salesPerEventDate', sort: 'desc' }, { field: 'total', sort: 'desc' } ],
          },
        }}
        experimentalFeatures={{ columnGrouping: true }}
        columnGroupingModel={columnGroupingModel}
        getCellClassName={(params) => {
          return params.row[params.field]?.type === PerTeamColumnKeys.produce ? 'borderRight' : '';
        }}
      />
    </>
  );
};