import { Box, ListItemAvatar, ListItemText } from '@mui/material';
import { missingImg } from 'assets';

export type SaleProductListItemProps = {
  imageUrl: string | undefined;
  name: string;
  secondary?: string;
  actions?: React.ReactNode;
  otherAction?: React.ReactNode;
  wrapActionsOnMobile?: boolean;
  bottomContent?: React.ReactNode;
};

export const SaleProductListItem: React.FC<SaleProductListItemProps> = props => {

  return (
    <Box
      component="li"
      display="flex"
      flexDirection="column"
    >
      <Box
        display="flex"
        flexDirection={{ xs: props.wrapActionsOnMobile ? 'column' : 'row', sm: 'row' }}
        rowGap={2}
        columnGap={3}
        py={1}
        px={{ xs: 1, sm: 2 }}
      >
        <Box flex={1} display="flex" alignItems="center">
          <ListItemAvatar sx={{ mr: 2 }}>
            <Box
              display="flex"
              component="img"
              src={props.imageUrl || missingImg}
              height="58px"
              borderRadius="50%"
            />
          </ListItemAvatar>
          <Box flex={1} display="flex">
            <ListItemText
              primary={props.name}
              primaryTypographyProps={{ fontWeight: 500 }}
              secondary={props.secondary}
              sx={{ mr: 1 }}
            />
            {props.otherAction && (
              <Box display="flex" alignItems="center" gap={1}>
                {props.otherAction}
              </Box>
            )}
          </Box>
        </Box>
        {props.actions && (
          <Box
            display="flex"
            alignItems="center"
            px={{ xs: props.wrapActionsOnMobile ? 1 : 0, sm: 0 }}
          >
            {props.actions}
          </Box>
        )}
      </Box>
      {props.bottomContent && (
        <Box display="flex" alignItems="center">
          {props.bottomContent}
        </Box>
      )}
    </Box>
  );

  // return (
  //   <ListItem sx={{ flexWrap: 'wrap-reverse' }}>
  //     <ListItemAvatar sx={{ mr: 2 }}>
  //       <Box
  //         display="flex"
  //         component="img"
  //         src={props.imageUrl || missingImg}
  //         height="58px"
  //         borderRadius="50%"
  //       />
  //     </ListItemAvatar>
  //     <ListItemText
  //       primary={props.name}
  //       primaryTypographyProps={{ fontWeight: 500 }}
  //       secondary={props.secondary}
  //       sx={{ mr: 1 }}
  //     />
  //     {props.actions && (
  //       <ListItemSecondaryAction
  //         sx={{
  //           position: { xs: props.wrapActionsOnMobile ? 'unset' : undefined, sm: 'absolute' }
  //         }}
  //       >
  //         {props.actions}
  //       </ListItemSecondaryAction>
  //     )}
  //   </ListItem>
  // );
};