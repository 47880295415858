import { EventPaymentTypeEnum } from 'api/resources';
import { StrictForm, UnknownEnum } from 'types';

export const enum EventOrganizerPageSection {
  OVERVIEW = 'overview',
  EVENTS = 'events'
}

export const enum EventOrganizerPageSectionRowId {
  NAME = 'name',
  WEBSITE = 'website',
  MAILING_ADDRESS = 'mailing_address',
  DEFAULT_PAYMENT_TYPE = 'default_payment_type',
  CONTACTS = 'contacts',
  NOTES = 'notes',
}

export type EventOrganizerSectionRowForm = {
  [EventOrganizerPageSectionRowId.NAME]: StrictForm<{
    name: string;
  }>;
  [EventOrganizerPageSectionRowId.WEBSITE]: StrictForm<{
    website: string;
  }>;
  [EventOrganizerPageSectionRowId.MAILING_ADDRESS]: StrictForm<{
    mailingAddress: string;
  }>;
  [EventOrganizerPageSectionRowId.DEFAULT_PAYMENT_TYPE]: StrictForm<{
    defaultPaymentType: EventPaymentTypeEnum | UnknownEnum.unknown;
  }>;
  [EventOrganizerPageSectionRowId.NOTES]: StrictForm<{
    notes: string;
  }>;
  [EventOrganizerPageSectionRowId.CONTACTS]: StrictForm<{
    name: string;
    title: string;
    phone: string;
    email: string;
    extension: string;
    note: string;
  }>;
};