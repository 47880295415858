import { Alert, Badge, Box, Button, Dialog, Divider, IconButton, Typography } from '@mui/material';
import { GridCellParams, GridColDef, GridRenderCellParams, GridRowClassNameParams, useGridApiRef } from '@mui/x-data-grid-pro';
import { GetProductsResponse, GetTeamsResponse } from 'api/actions';
import { useCallback, useMemo, useState } from 'react';
import { UpdateQtyCellContent } from './UpdateQtyCellContent.component';
import { EditModeSaveAndResetButtons, NumberPad, TableProps } from 'components';
import { StockQtyCellContent } from './StockQtyCellContent.component';
import { StockMetaCellContent, StockMetaConfig } from './StockMetaCellContent.component';
import { AutoFixHighOutlined, RefreshOutlined, SaveOutlined } from '@mui/icons-material';
import { Toolbar } from './Toolbar.component';
import { TransferLabel } from './TransferLabel.component';
import { DateService } from 'services';
import { LatestUpdateWrapper } from './LatestUpdateWrapper.component';
import { getProductTeamBaseline } from 'helpers';
import { UnknownEnum } from 'types';

export const enum InventoryTableEditableMode {
  set = 'set',
  unary = 'unary',
  transfer = 'transfer',
}

type LatestUpdates = {
  [productId: string]: {
    [teamId: string]: string;
  };
};

const onSetLatestUpdatesHandler = (params: SaveInventoryEditsParams, setLatestUpdates: React.Dispatch<React.SetStateAction<LatestUpdates>>) => {
  const { transferConfig, edits } = params;

  setLatestUpdates((currentLatest) => {
    return Object.entries(edits).reduce((allLatest, [ productId, productUpdates ]) => {

      const jawns = Object.keys(productUpdates).reduce((productLatest, teamId) => {
        const timestamp = new Date().toISOString();

        if (params.isTransferOperation) {
          return { ...productLatest, [teamId]: timestamp, [transferConfig.out]: timestamp };
        }

        return { ...productLatest, [teamId]: new Date().toISOString() };
      }, currentLatest[productId] ?? {});

      return { ...allLatest, [productId]: jawns };
    }, currentLatest);
  });
};

export type InventoryEdit = {
  value: number;
  error?: string;
  loading?: boolean;
};

export type InventoryEdits = {
  [productId: string]: {
    [teamId: string]: InventoryEdit;
  };
};

export type SaveInventoryEditsParams = {
  transferConfig: TransferConfig;
  edits: InventoryEdits;
  isSetOperation: boolean;
  isTransferOperation: boolean;
  products: InventoryRow[];
};

type OnSaveFunc = (params: SaveInventoryEditsParams) => Promise<void>;

export type UseInventoryTableArgs = {
  products: InventoryRow[];
  teams:  GetTeamsResponse['data'];
  rowFilter?: (row: InventoryRow) => boolean;
  initialTeamIds?: string[];
  initialTransferConfig?: TransferConfig | null;
  extraColumns?: GridColDef<InventoryRow>[];
  validateUnaryEdit?: UpdateValidator<ValidateUnaryEditParams>;
  validateSetEdit?: UpdateValidator<ValidateSetEditParams>;
  validateTransferEdit?: UpdateValidator<ValidateTransferEditParams>;
  onSaveRow?: OnSaveFunc;
  onSaveAll?: OnSaveFunc;
  initialShowStockMeta?: boolean;
  teamsStockMetaConfig?: { [teamId: string]: StockMetaConfig };
  initialMode?: InventoryTableEditableMode;
  initialSettingsModalOpen?: boolean;
  initialInventoryEdits?: InventoryEdits;
  getDisplayStandardFilters?: (mode: InventoryTableEditableMode | UnknownEnum) => StandardInventoryTableFilters[];
  initialStandardFilters?: StandardInventoryTableFilters[];
  getRowInputsDisabled?: (row: InventoryRow) => boolean;
  hideReset?: boolean;
  hideSaveColumn?: boolean;
  overrideToolbarActions?: React.ReactNode;
  getCellInputError?: UpdateValidator<{ stock: InventoryRow['stocks'][string]; updateQty: number | undefined }>;
  allowZeroInTransferMode?: boolean;
};

export type TransferConfig = {
  in: string;
  out: string;
  autoFillBase?: 'in' | 'out';
};

export type InventoryRow = Pick<GetProductsResponse['data'][number], '_id' | 'name' | 'availability' | 'stocks' | 'activeBaseline' | 'activeWarehouseBaseline' | 'autoBaseline' | 'autoWarehouseBaseline' | 'manualBaseline' | 'manualWarehouseBaseline'>;

const getStock = (row: InventoryRow, teamId: string) => row.stocks[teamId];

type UpdateValidator<Params> = (params: Params) => string | undefined;

type ValidateUnaryEditParams = {
  stock: InventoryRow['stocks'][string];
  updateQty: number;
};

const defaultValidateUnaryEdit: UpdateValidator<ValidateUnaryEditParams> = () => {
  return undefined;
};

type ValidateSetEditParams = {
  stock: InventoryRow['stocks'][string];
  updateQty: number;
};

const defaultValidateSetEdit: UpdateValidator<ValidateSetEditParams> = () => {
  // if (params.stock.quantity === params.updateQty) {
  //   return 'Cannot set to same quantity';
  // }

  return undefined;
};

type ValidateTransferEditParams = {
  inStock: InventoryRow['stocks'][string];
  outStock: InventoryRow['stocks'][string];
  updateQty: number;
};

const defaultValidateTransferEdit: UpdateValidator<ValidateTransferEditParams> = (params) => {
  if (params.outStock.quantity - params.updateQty < 0) {
    return `Cannot transfer more than ${params.outStock.quantity}`;
  }
};

type CellEdit = {
  productId: string;
  teamId: string;
};

type TransferFilters = 'showNoOutStock' | 'showInNotNeeded' | 'showOutNotOverstocked';
export type StandardInventoryTableFilters = 'onlyShowEdits' | 'showNotAvailableAtEvents' | TransferFilters;

export const useInventoryTable = ({
  products,
  teams,
  rowFilter,
  initialTeamIds = [],
  initialTransferConfig = null,
  validateUnaryEdit = defaultValidateUnaryEdit,
  validateSetEdit = defaultValidateSetEdit,
  validateTransferEdit = defaultValidateTransferEdit,
  onSaveRow,
  onSaveAll,
  initialShowStockMeta = true,
  teamsStockMetaConfig,
  initialMode,
  initialSettingsModalOpen = false,
  initialInventoryEdits = {},
  getRowInputsDisabled = () => false,
  getDisplayStandardFilters,
  initialStandardFilters = [],
  hideReset = false,
  hideSaveColumn = false,
  overrideToolbarActions,
  getCellInputError = () => undefined,
  allowZeroInTransferMode,
}: UseInventoryTableArgs) => {
  const gridRef = useGridApiRef();
  const [ latestUpdates, setLatestUpdates ] = useState<LatestUpdates>({});
  const [ saveLoading, setSaveLoading ] = useState<'all' | {productId: string} | false>(false);
  const [ standardFilters, setStandardFilters ] = useState<StandardInventoryTableFilters[]>(initialStandardFilters);
  const [ teamIds, setTeamIds ] = useState<string[]>(initialTeamIds);
  const [ transferConfig, setTransferConfig ] = useState<TransferConfig>(initialTransferConfig ?? { in: teams[0]._id, out: teams[1]._id });
  const [ settingsModalOpen, setSettingsModalOpen ] = useState(initialSettingsModalOpen);
  const [ inventoryEdits, setInventoryEdits ] = useState<InventoryEdits>(initialInventoryEdits);
  const [ mode, setMode ] = useState<InventoryTableEditableMode | UnknownEnum>(initialMode ?? UnknownEnum.unknown);
  const isSetOperation = mode === InventoryTableEditableMode.set;
  const isTransferOperation = mode === InventoryTableEditableMode.transfer;
  const [ showStockMeta, setShowStockMeta ] = useState(initialShowStockMeta);
  const [ cellEditModalOpen, setCellEditModalOpen ] = useState<CellEdit | null>(null);

  const onSetLatestUpdates = (params: SaveInventoryEditsParams) => onSetLatestUpdatesHandler(params, setLatestUpdates);
  const displayStandardFilters = useMemo(() => getDisplayStandardFilters?.(mode) ?? [], [ getDisplayStandardFilters, mode ]);

  const getLatestStockUpdate = useCallback((productId: string, teamId: string) => {
    const latest = latestUpdates?.[productId]?.[teamId];

    if (latest) {
      return DateService.dayjs(latest).fromNow();
    }
  }, [ latestUpdates ]);

  const filteredRows = useMemo(() => {
    return products.filter(product => {
      // never hide products with edits
      if(product._id in inventoryEdits) {
        return true;
      }

      if (displayStandardFilters.includes('showNotAvailableAtEvents') && (!standardFilters.includes('showNotAvailableAtEvents') || transferConfig)) {
        if (!product.availability?.events) {
          return false;
        }
      }

      if (displayStandardFilters.includes('onlyShowEdits') && standardFilters.includes('onlyShowEdits')) {
        if (!inventoryEdits[product._id]) {
          return false;
        }
      }

      if (isTransferOperation) {

        if (displayStandardFilters.includes('showNoOutStock') && !standardFilters.includes('showNoOutStock')) {
          const outStock = getStock(product, transferConfig.out);

          if (outStock.quantity === 0) {
            return false;
          }
        }

        if (displayStandardFilters.includes('showOutNotOverstocked') && !standardFilters.includes('showOutNotOverstocked')) {
          const outStock = getStock(product, transferConfig.out);
          const outTeam = teams.filter(team => team._id === transferConfig.out)[0];
          const baseline = getProductTeamBaseline(product, outTeam);

          if (baseline >= outStock.quantity) {
            return false;
          }
        }

        if (displayStandardFilters.includes('showInNotNeeded') && !standardFilters.includes('showInNotNeeded')) {
          const inStock = getStock(product, transferConfig.in);
          const inTeam = teams.filter(team => team._id === transferConfig.in)[0];
          const baseline = getProductTeamBaseline(product, inTeam);

          if (baseline <= inStock.quantity) {
            return false;
          }
        }
      }

      if (rowFilter) {
        return rowFilter(product);
      }

      return true;
    });
  }, [ products, inventoryEdits, displayStandardFilters, standardFilters, transferConfig, isTransferOperation, rowFilter, teams ]);

  const getTeamColumnBase = useCallback((team: GetTeamsResponse['data'][number]): GridColDef<InventoryRow> => {
    let width = 285;

    if (isTransferOperation) {
      width -= 50;
    }
    if (showStockMeta) {
      width += 25;
    }

    return {
      field: transferConfig ? team._id + '_transfer' : team._id,
      headerName: team.name,
      headerAlign: 'center',
      cellClassName: 'inventoryTableCell',
      width,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: ({ row }) => getStock(row, team._id).quantity,
    };
  }, [ isTransferOperation, showStockMeta, transferConfig ]);

  const productHash = useMemo(() => products.reduce((acc: Record<string, number>, product, idx) => ({ ...acc, [product._id]: idx }),{}), [ products ]);
  const getProduct = useCallback((productId: string) => products[productHash[productId]], [ productHash, products ]);
  const getInventoryEdit = useCallback((row: InventoryRow, teamId: string): InventoryEdit | undefined => {
    const productEdits = inventoryEdits[row._id];

    if (!productEdits || !productEdits[teamId]) {
      return;
    }

    let loading: boolean | undefined;

    if (saveLoading === 'all') {
      loading = true;
    } else if (saveLoading && saveLoading.productId === row._id) {
      loading = true;
    }

    return { ...productEdits[teamId], loading };
  }, [ inventoryEdits, saveLoading ]);

  const allEdits = useMemo(() => {
    const productKeys = Object.keys(inventoryEdits);

    return productKeys.reduce((acc: InventoryEdit[], productId) => {
      const teamKeys = Object.keys(inventoryEdits[productId]);

      const edits = teamKeys.reduce((acc: InventoryEdit[], teamId) => {

        return [ ...acc, inventoryEdits[productId][teamId] ];
      }, []);

      return [ ...acc, ...edits ];
    }, []);
  }, [ inventoryEdits ]);

  const getEditsForProduct = (productId: string) => {
    const productEdits = inventoryEdits[productId];

    if (!productEdits) {
      return [];
    }

    return Object.keys(productEdits).map(teamId => productEdits[teamId]);
  };

  const hasEdits = allEdits.length > 0;

  const getInputErrorsForProduct = (row: InventoryRow) => {
    if (isTransferOperation) {
      return !!getCellInputError({ stock: getStock(row, transferConfig.in), updateQty: getInventoryEdit(row, transferConfig.in)?.value });
    }

    return teamIds.some((teamId) => !!getCellInputError({ stock: getStock(row, teamId), updateQty: getInventoryEdit(row, teamId)?.value }));
  };

  const hasInputErrors = filteredRows.some(getInputErrorsForProduct);

  const hasEditErrors = allEdits.some(edit => edit.error !== undefined);
  const hasErrors = hasEditErrors || hasInputErrors;

  const api = useMemo(() => {
    return {
      removeProductEdit: (productId: string) => {
        setInventoryEdits(prev => {
          const { [productId]: _, ...restOfEdits } = prev;

          return restOfEdits;
        });
      },
      setEdit: (params: SetEditParams) => {
        const { productId, teamId, newValue } = params;

        let value: number | undefined;

        if (newValue) {
          if (!/^[1-9][0-9]*$/.test(newValue)) {
            if ((isSetOperation || (isTransferOperation && allowZeroInTransferMode)) && newValue.startsWith('0')) {
              if (newValue === '0') {
                value = 0;
              } else {
                value = Number(newValue.slice(-1));
              }
            } else {
              return;
            }
          }

          if (newValue.length > 2) {
            return;
          }

          value = newValue ? Number(newValue) : undefined;
        }

        setInventoryEdits(prev => {
          if (value === undefined) {
            const { [teamId]: _, ...restOfProductEdits } = prev[productId];

            if (Object.keys(restOfProductEdits).length === 0) {
              const { [productId]: _, ...restOfEdits } = prev;

              return restOfEdits;
            }

            return { ...prev, [productId]: restOfProductEdits };
          }

          const product = getProduct(productId);
          let error: string | undefined;

          if (isTransferOperation) {
            const inStock = getStock(product, transferConfig.in);
            const outStock = getStock(product, transferConfig.out);

            error = validateTransferEdit({ inStock, outStock, updateQty: value });
          } else {
            const stock = getStock(product, teamId);

            if (isSetOperation) {
              error = validateSetEdit({ stock, updateQty: value });
            } else {
              error = validateUnaryEdit({ stock, updateQty: value });
            }
          }

          return {
            ...prev,
            [productId]: {
              ...prev[productId],
              [teamId]: {
                value,
                error,
              },
            }
          };
        });
      },
    };
  }, [ allowZeroInTransferMode, getProduct, isSetOperation, isTransferOperation, transferConfig.in, transferConfig.out, validateSetEdit, validateTransferEdit, validateUnaryEdit ]);

  const editModal = useMemo(() => {

    if (cellEditModalOpen) {
      const { productId, teamId } = cellEditModalOpen;
      const product = getProduct(productId);
      const inventoryEdit = getInventoryEdit(product, teamId);

      return (
        <Dialog
          open
          maxWidth="xs"
          PaperProps={{ sx: { width: '100%', mx: theme => theme.spacing(2) } }}
          onClose={() => setCellEditModalOpen(null)}
        >
          <Box px={2} py={1} display="flex" justifyContent="space-between" alignItems="center">
            <Typography fontWeight={500} fontSize="large">{product.name}</Typography>
            <Button onClick={() => setCellEditModalOpen(null)}>Done</Button>
          </Box>
          <Divider />
          <Box position="relative">
            {inventoryEdit?.error && (
              <Box position="absolute" top={0} right={0} width="fit-content">
                <Alert severity="error" sx={{ borderBottomLeftRadius: theme => theme.spacing(1) }}>{inventoryEdit.error}</Alert>
              </Box>
            )}
            <NumberPad
              value={inventoryEdit?.value !== undefined ? String(inventoryEdit.value) : ''}
              onChange={(value) => api.setEdit({ teamId: teamId, productId: productId, newValue: value })}
              preventNegative
              error={inventoryEdit?.error}
            />
          </Box>
        </Dialog>
      );
    }
  }, [ api, cellEditModalOpen, getInventoryEdit, getProduct ]);

  const teamColumns = useMemo(() => {
    if (isTransferOperation) {
      const { in: inTeamId, out: outTeamId } = transferConfig;

      const inTeam = teams.filter(team => team._id === inTeamId)[0];
      const inColumn: GridColDef<InventoryRow> = {
        ...getTeamColumnBase(inTeam),
        renderHeader: () => <TransferLabel isIn team={inTeam} />,
        renderCell: ({ row }) => {
          const stock= getStock(row, inTeamId);
          const inventoryEdit = getInventoryEdit(row, inTeamId);

          return (
            <LatestUpdateWrapper display="flex" alignItems="center" width="100%" gap={1} height="100%" latestUpdate={getLatestStockUpdate(row._id, inTeamId)}>
              <Box flex={1} textAlign="center">
                <StockQtyCellContent quantity={stock.quantity} updateQuantity={inventoryEdit?.value} />
              </Box>
              {showStockMeta && <StockMetaCellContent config={teamsStockMetaConfig?.[inTeamId]} isSetOperation={isSetOperation} stock={stock} updateQuantity={inventoryEdit?.value} baseline={getProductTeamBaseline(row, inTeam)} />}
            </LatestUpdateWrapper>
          );
        }
      };

      const outTeam = teams.filter(team => team._id === outTeamId)[0];
      const outColumn: GridColDef<InventoryRow> = {
        ...getTeamColumnBase(outTeam),
        renderHeader: () => <TransferLabel isOut team={outTeam} />,
        renderCell: ({ row }) => {
          const stock = getStock(row, outTeamId);
          const inventoryEdit = getInventoryEdit(row, inTeamId);
          const updateQuantity = inventoryEdit ? -inventoryEdit.value : undefined;

          return (
            <LatestUpdateWrapper display="flex" alignItems="center" width="100%" gap={1} height="100%" latestUpdate={getLatestStockUpdate(row._id, outTeamId)} latestUpdatePosition="right">
              {showStockMeta && <StockMetaCellContent config={teamsStockMetaConfig?.[outTeamId]} isSetOperation={isSetOperation} stock={stock} updateQuantity={updateQuantity} baseline={getProductTeamBaseline(row, outTeam)} />}
              <Box flex={1} textAlign="center">
                <StockQtyCellContent quantity={stock.quantity} updateQuantity={updateQuantity} />
              </Box>
            </LatestUpdateWrapper>
          );
        }
      };

      const editColumn: GridColDef<InventoryRow> = {
        field: 'action',
        headerName: '>>>',
        align: 'center',
        headerAlign: 'center',
        cellClassName: 'inventoryTableCell',
        width: 170,
        disableColumnMenu: true,
        sortable: false,
        renderCell: ({ row, field }) => {
          const inventoryEdit = getInventoryEdit(row, inTeamId);
          const outTeamStock = getStock(row, outTeamId);
          const outTeamBaseline = getProductTeamBaseline(row, outTeam);
          const inTeamStock = getStock(row, inTeamId);
          const inTeamBaseline = getProductTeamBaseline(row, inTeam);
          const isDisabled = getRowInputsDisabled(row);
          const error = getCellInputError({ stock: inTeamStock, updateQty: inventoryEdit?.value });

          const maxQuantity = outTeamStock.quantity;
          const neededQuantity = transferConfig.autoFillBase === 'out' ? Math.max(outTeamStock.quantity - outTeamBaseline, 0) : Math.max(inTeamBaseline - inTeamStock.quantity, 0);

          const autoFillValue = Math.min(maxQuantity, neededQuantity);
          const isAutoFilled = inventoryEdit?.value === autoFillValue;

          return (
            <Box display="flex" alignItems="center" gap={1} justifyContent="space-between" width="100%">
              <UpdateQtyCellContent
                disabled={isDisabled}
                loading={inventoryEdit?.loading}
                error={inventoryEdit?.error ?? error}
                row={row}
                value={inventoryEdit?.value}
                onChange={(value) => api.setEdit({ teamId: inTeamId, productId: row._id, newValue: value })}
                field={field}
                onEditCell={() => setCellEditModalOpen({ productId: row._id, teamId: inTeamId })}
              />
              <Box flex={1} textAlign="center">
                <IconButton
                  sx={{
                    visibility: autoFillValue && !isAutoFilled && !isDisabled ? 'visible' : 'hidden',
                  }}
                  color="primary"
                  onClick={() => api.setEdit({ teamId: inTeamId, productId: row._id, newValue: String(autoFillValue) })}
                >
                  <Badge badgeContent={autoFillValue} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                    <AutoFixHighOutlined />
                  </Badge>
                </IconButton>
              </Box>
            </Box>
          );
        }
      };

      return [ outColumn, editColumn, inColumn ];
    }

    const selectedTeams = teams.filter(team => teamIds.includes(team._id));

    return selectedTeams.map((team): GridColDef<InventoryRow> => {
      const teamId = team._id;

      return {
        ...getTeamColumnBase(team),
        renderCell: ({ row, field }) => {
          const stock = getStock(row, teamId);
          const inventoryEdit = getInventoryEdit(row, teamId);
          const latestUpdate = getLatestStockUpdate(row._id, teamId);
          const isDisabled = getRowInputsDisabled(row);
          const error = getCellInputError({ stock, updateQty: inventoryEdit?.value });

          if (!team.isWarehouse && !row.availability?.events) {
            return <Typography fontSize="small" fontStyle="italic">Not at events</Typography>;
          }

          return (
            <LatestUpdateWrapper display="flex" alignItems="center" width="100%" gap={1} height="100%" latestUpdate={latestUpdate}>
              <Box flex={1} textAlign="center">
                <StockQtyCellContent isSetOperation={isSetOperation} quantity={stock.quantity} updateQuantity={inventoryEdit?.value} />
              </Box>
              <UpdateQtyCellContent
                disabled={isDisabled}
                loading={inventoryEdit?.loading}
                error={inventoryEdit?.error ?? error}
                row={row}
                value={inventoryEdit?.value}
                onChange={(value) => api.setEdit({ teamId: teamId, productId: row._id, newValue: value })}
                field={field}
                onEditCell={() => setCellEditModalOpen({ productId: row._id, teamId: teamId })}
              />
              {showStockMeta && <StockMetaCellContent isSetOperation={isSetOperation} stock={stock} updateQuantity={inventoryEdit?.value} baseline={getProductTeamBaseline(row, team)} />}
            </LatestUpdateWrapper>
          );
        }
      };
    });
  }, [ api, getCellInputError, getInventoryEdit, getLatestStockUpdate, getRowInputsDisabled, getTeamColumnBase, isSetOperation, isTransferOperation, showStockMeta, teamIds, teams, teamsStockMetaConfig, transferConfig ]);

  const getTeamIdFromField = (field: string) => transferConfig ? field.replace('_transfer', '') : field;

  const onSave = async (saveFunc: OnSaveFunc, productId?: string) => {
    setSaveLoading(productId ? { productId } : 'all');
    const edits = productId ? { [productId]: inventoryEdits[productId] } : inventoryEdits;

    const saveParams: SaveInventoryEditsParams = {
      edits,
      transferConfig,
      isSetOperation,
      isTransferOperation,
      products,
    };

    await saveFunc(saveParams);

    if (productId) {
      api.removeProductEdit(productId);
    } else {
      setInventoryEdits({});
    }

    onSetLatestUpdates(saveParams);
    setSaveLoading(false);
  };

  const inventoryTableProps: TableProps = {
    apiRef: gridRef,
    showCellVerticalBorder: true,
    rows: filteredRows,
    columns: [
      {
        field: 'name',
        headerName: 'Product',
        width: 200,
        cellClassName: 'productName',
        disableColumnMenu: true,
      },
      ...teamColumns,
    ].concat(hideSaveColumn ? [] : [
      {
        field: 'save',
        headerName: '',
        align: 'right',
        sortable: false,
        disableColumnMenu: true,
        resizable: false,
        cellClassName: 'inventoryTableCell',
        width: onSaveRow ? 104 : 56,
        renderCell: ({ row }: GridRenderCellParams<InventoryRow>) => {
          const productEdits = getEditsForProduct(row._id);
          const hasErrors = productEdits.some(edit => edit.error);

          return (
            <Box display="flex" gap={1}>
              {!hideReset && (
                <IconButton
                  color="warning"
                  disabled={!productEdits.length}
                  onClick={() => api.removeProductEdit(row._id)}
                  title="Reset Row"
                >
                  <RefreshOutlined />
                </IconButton>
              )}
              {onSaveRow && (
                <IconButton
                  color="success"
                  disabled={!productEdits.length || hasErrors}
                  onClick={() => onSave(onSaveRow, row._id)}
                  title="Save Row"
                >
                  <SaveOutlined />
                </IconButton>
              )}
            </Box>
          );
        }
      }
    ]),
    pinnedColumns: {
      right: [ 'save' ],
      left: [ 'name' ]
    },
    getRowId: row => row._id,
    slots: { toolbar: Toolbar },
    rowHeight: 70,
    rowBuffer: 5,
    slotProps: {
      toolbar: {
        showStockMeta,
        isSetMode: isSetOperation && !transferConfig,
        onOpenSettings: () => setSettingsModalOpen(true),
        actions: overrideToolbarActions ?? (
          <EditModeSaveAndResetButtons
            resetDisabled={allEdits.length === 0}
            saveDisabled={hasErrors || allEdits.length === 0}
            onSaveChanges={() => onSaveAll && onSave(onSaveAll)}
            onReset={() => setInventoryEdits({})}
            hideSaveButton={!onSaveAll}
            hideResetButton={hideReset}
          />
        )
      }
    },
    getCellClassName: (params: GridCellParams<InventoryRow>) => {
      const teamId = getTeamIdFromField(params.field);
      const hasEdit = getInventoryEdit(params.row, teamId);

      if (hasEdit) {
        return 'cellHasEdit';
      }

      if (isTransferOperation && [ 'action', transferConfig.out ].includes(teamId) && getInventoryEdit(params.row, transferConfig.in)) {
        return 'cellHasEdit';
      }

      return '';
    },
    getRowClassName: (params: GridRowClassNameParams<InventoryRow>) => {
      if (getInputErrorsForProduct(params.row)) {
        return 'rowHighlight rowHasErrors';
      }

      if (inventoryEdits[params.row._id]) {
        if (getEditsForProduct(params.row._id).some(edit => edit.error)) {
          return 'rowHighlight rowHasErrors';
        }

        return 'rowHighlight';
      }

      return '';
    },
    columnHeaderHeight: 32,
    sx: {
      minHeight: '700px',
      '& .MuiDataGrid-row:hover': {
        backgroundColor: 'transparent',
      },
      '&.MuiDataGrid-root .Mui-hovered': {
        backgroundColor: 'transparent',
      },
      '& .inventoryTableCell': {
        px: 1,
      },
      '& .cellHasEdit': {
        background: theme => theme.palette.grey[50]
      },
      '& .rowHighlight': {
        '& .productName': {
          position: 'relative',
          pl: 2,
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '4px',
            height: '100%',
            background: theme => theme.palette.success.main,
          },
        },
      },
      '& .rowHasErrors': {
        '& .productName': {
          '&::after': {
            background: theme => theme.palette.error.main,
          }
        }
      }
    }
  };

  return {
    inventoryTableProps,
    editModal,
    teamIds,
    setTeamIds,
    transferConfig,
    setTransferConfig,
    mode,
    setMode,
    showStockMeta,
    setShowStockMeta,
    settingsModalOpen,
    setSettingsModalOpen,
    standardFilters,
    setStandardFilters,
    hasEdits,
    hasErrors,
    inventoryEdits,
    setSaveLoading,
    getInputErrorsForProduct
  };
};

type SetEditParams = {
  productId: string;
  teamId: string;
  newValue: string;
};