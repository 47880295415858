import { EventCommunicationLogTypeEnum, EventElectricityChoiceEnum, EventFileTypeEnum, EventForResourceTypeEnum, EventFutureInterestStatusEnum, EventIndoorOurdoorEnum, EventInsuranceSharedEnum, EventInsuranceStatusEnum, EventLodgingBookingStatusEnum, EventPreperationAcceptanceStatusEnum, EventPreperationApplicationPlatformEnum, EventPreperationApplicationStatusEnum, EventPreperationParticipationStatusEnum, EventPaymentTypeEnum } from 'api/resources';
import { EnumHelpers, EnumHelpersWithIcon } from './types';
import { EventDatesStatusEnum, EventStaffStatusEnum, UnknownEnum } from 'types';
import { EmailOutlined, ForumOutlined, PhoneOutlined, VoicemailOutlined } from '@mui/icons-material';
import LanguageIcon from '@mui/icons-material/Language';
import SendIcon from '@mui/icons-material/Send';
import MailIcon from '@mui/icons-material/Mail';
import HelpIcon from '@mui/icons-material/Help';
import { ZapplicationIcon, SubmittableIcon, EventenyIcon, EntryThingyIcon } from 'components';

const applicationStatus: EnumHelpers<EventPreperationApplicationStatusEnum | UnknownEnum> = {
  enumValues: [
    EventPreperationApplicationStatusEnum.applied,
    EventPreperationApplicationStatusEnum.research,
    EventPreperationApplicationStatusEnum.needApply,
    EventPreperationApplicationStatusEnum.inProccess,
    UnknownEnum.unknown,
  ],
  getLabel: (value) => {
    switch (value) {
      case EventPreperationApplicationStatusEnum.research:
        return 'Research';
      case EventPreperationApplicationStatusEnum.needApply:
        return 'Need To Apply';
      case EventPreperationApplicationStatusEnum.inProccess:
        return 'In Process';
      case EventPreperationApplicationStatusEnum.applied:
        return 'Applied';
      default:
        return 'Unknown';
    }
  },
  getColor: (value) => {
    switch (value) {
      case EventPreperationApplicationStatusEnum.research:
        return 'threat';
      case EventPreperationApplicationStatusEnum.needApply:
        return 'error';
      case EventPreperationApplicationStatusEnum.inProccess:
        return 'warning';
      case EventPreperationApplicationStatusEnum.applied:
        return 'success';
      default:
        return 'unknown';
    }
  },
};

const participationStatus: EnumHelpers<EventPreperationParticipationStatusEnum | UnknownEnum> = {
  enumValues: [
    EventPreperationParticipationStatusEnum.interested,
    EventPreperationParticipationStatusEnum.participating,
    EventPreperationParticipationStatusEnum.canceled,
    EventPreperationParticipationStatusEnum.unable,
    EventPreperationParticipationStatusEnum.notInterested,
    UnknownEnum.unknown
  ],
  getLabel: (value) => {
    switch (value) {
      case EventPreperationParticipationStatusEnum.interested:
        return 'Interested';
      case EventPreperationParticipationStatusEnum.participating:
        return 'Participating';
      case EventPreperationParticipationStatusEnum.notInterested:
        return 'Not Interested';
      case EventPreperationParticipationStatusEnum.canceled:
        return 'Canceled';
      case EventPreperationParticipationStatusEnum.unable:
        return 'Unable';
      default:
        return 'Unknown';
    }
  },
  getColor: (value) => {
    switch (value) {
      case EventPreperationParticipationStatusEnum.interested:
        return 'success';
      case EventPreperationParticipationStatusEnum.participating:
        return 'success';
      case EventPreperationParticipationStatusEnum.notInterested:
        return 'error';
      case EventPreperationParticipationStatusEnum.canceled:
        return 'error';
      case EventPreperationParticipationStatusEnum.unable:
        return 'threat';
      default:
        return 'unknown';
    }
  },
};

const acceptanceStatus: EnumHelpers<EventPreperationAcceptanceStatusEnum | UnknownEnum> = {
  enumValues: [
    EventPreperationAcceptanceStatusEnum.accepted,
    EventPreperationAcceptanceStatusEnum.followUp,
    EventPreperationAcceptanceStatusEnum.waitListPending,
    EventPreperationAcceptanceStatusEnum.waitListDenied,
    EventPreperationAcceptanceStatusEnum.denied,
    UnknownEnum.unknown,
  ],
  getLabel: (value) => {
    switch (value) {
      case EventPreperationAcceptanceStatusEnum.accepted:
        return 'Accepted';
      case EventPreperationAcceptanceStatusEnum.denied:
        return 'Denied';
      case EventPreperationAcceptanceStatusEnum.followUp:
        return 'Pending';
      case EventPreperationAcceptanceStatusEnum.waitListPending:
        return 'Wait List Pending';
      case EventPreperationAcceptanceStatusEnum.waitListDenied:
        return 'Wait List Denied';
      default:
        return 'Unknown';
    }
  },
  getColor: (value) => {
    switch (value) {
      case EventPreperationAcceptanceStatusEnum.accepted:
        return 'success';
      case EventPreperationAcceptanceStatusEnum.denied:
        return 'error';
      case EventPreperationAcceptanceStatusEnum.followUp:
        return 'warning';
      case EventPreperationAcceptanceStatusEnum.waitListPending:
        return 'info';
      case EventPreperationAcceptanceStatusEnum.waitListDenied:
        return 'error';
      default:
        return 'unknown';
    }
  },
};

const applicationPlatform: EnumHelpersWithIcon<EventPreperationApplicationPlatformEnum | UnknownEnum> = {
  enumValues: [
    EventPreperationApplicationPlatformEnum.mail,
    EventPreperationApplicationPlatformEnum.email,
    EventPreperationApplicationPlatformEnum.hostsWebsite,
    EventPreperationApplicationPlatformEnum.entryThingy,
    EventPreperationApplicationPlatformEnum.eventeny,
    EventPreperationApplicationPlatformEnum.submittable,
    EventPreperationApplicationPlatformEnum.zapplication,
    UnknownEnum.unknown,
  ],
  getLabel: (value) => {
    switch (value) {
      case EventPreperationApplicationPlatformEnum.mail:
        return 'Mail';
      case EventPreperationApplicationPlatformEnum.email:
        return 'Email';
      case EventPreperationApplicationPlatformEnum.hostsWebsite:
        return 'Hosts Website';
      case EventPreperationApplicationPlatformEnum.zapplication:
        return 'Zapplication';
      case EventPreperationApplicationPlatformEnum.entryThingy:
        return 'EntryThingy';
      case EventPreperationApplicationPlatformEnum.submittable:
        return 'Submittable';
      case EventPreperationApplicationPlatformEnum.eventeny:
        return 'Eventeny';
      default:
        return 'Unknown';
    }
  },
  getColor: (value) => {
    if (value && value !== UnknownEnum.unknown) {
      return 'success';
    }

    return 'unknown';
  },
  getIcon: (value) => {
    switch (value) {
      case EventPreperationApplicationPlatformEnum.mail:
        return MailIcon;
      case EventPreperationApplicationPlatformEnum.email:
        return SendIcon;
      case EventPreperationApplicationPlatformEnum.hostsWebsite:
        return LanguageIcon;
      case EventPreperationApplicationPlatformEnum.zapplication:
        return ZapplicationIcon;
      case EventPreperationApplicationPlatformEnum.entryThingy:
        return EntryThingyIcon;
      case EventPreperationApplicationPlatformEnum.submittable:
        return SubmittableIcon;
      case EventPreperationApplicationPlatformEnum.eventeny:
        return EventenyIcon;
      default:
        return HelpIcon;
    }
  }
};

const paymentType: EnumHelpers<EventPaymentTypeEnum> = {
  enumValues: [
    EventPaymentTypeEnum.check,
    EventPaymentTypeEnum.credit,
    EventPaymentTypeEnum.creditForm,
    EventPaymentTypeEnum.wireTransfer,
  ],
  getLabel: (value) => {
    switch (value) {
      case EventPaymentTypeEnum.credit:
        return 'Credit Payment';
      case EventPaymentTypeEnum.check:
        return 'Check Payment';
      case EventPaymentTypeEnum.creditForm:
        return 'Credit Form';
      case EventPaymentTypeEnum.wireTransfer:
        return 'Wire Transfer';
      default:
        return 'Unknown';
    }
  },
  getColor: () => 'unknown'
};

const insuranceStatus: EnumHelpers<EventInsuranceStatusEnum | UnknownEnum> = {
  enumValues: [
    EventInsuranceStatusEnum.obtained,
    UnknownEnum.unknown,
  ],
  getLabel: (value) => {
    switch (value) {
      case EventInsuranceStatusEnum.obtained:
        return 'Obtained';
      default:
        return 'Unknown';
    }
  },
  getColor: (value) => {
    switch (value) {
      case EventInsuranceStatusEnum.obtained:
        return 'success';
      default:
        return 'unknown';
    }
  }
};

const insuranceShared: EnumHelpers<EventInsuranceSharedEnum | UnknownEnum> = {
  enumValues: [
    EventInsuranceSharedEnum.shared,
    UnknownEnum.unknown,
  ],
  getLabel: (value) => {
    switch (value) {
      case EventInsuranceSharedEnum.shared:
        return 'Shared';
      default:
        return 'Unknown';
    }
  },
  getColor: (value) => {
    switch (value) {
      case EventInsuranceSharedEnum.shared:
        return 'success';
      default:
        return 'unknown';
    }
  }
};

const communicationLog: EnumHelpersWithIcon<EventCommunicationLogTypeEnum> = {
  enumValues: [
    EventCommunicationLogTypeEnum.call,
    EventCommunicationLogTypeEnum.email,
    EventCommunicationLogTypeEnum.other,
    EventCommunicationLogTypeEnum.voicemail
  ],
  getLabel: (value) => {
    switch (value) {
      case EventCommunicationLogTypeEnum.call:
        return 'Call';
      case EventCommunicationLogTypeEnum.voicemail:
        return 'Voicemail';
      case EventCommunicationLogTypeEnum.email:
        return 'Email';
      default:
        return 'Other';
    }
  },
  getIcon: (value) => {
    switch (value) {
      case EventCommunicationLogTypeEnum.call:
        return PhoneOutlined;
      case EventCommunicationLogTypeEnum.voicemail:
        return VoicemailOutlined;
      case EventCommunicationLogTypeEnum.email:
        return EmailOutlined;
      default:
        return ForumOutlined;
    }
  },
  getColor: () => 'unknown'
};

const file: EnumHelpers<EventFileTypeEnum> = {
  enumValues: [
    EventFileTypeEnum.application,
    EventFileTypeEnum.lodging,
    EventFileTypeEnum.insurance,
    EventFileTypeEnum.other
  ],
  getLabel: (value) => {
    switch (value) {
      case EventFileTypeEnum.application:
        return 'Application';
      case EventFileTypeEnum.lodging:
        return 'Lodging';
      case EventFileTypeEnum.insurance:
        return 'Insurance';
      default:
        return 'Other';
    }
  },
  getColor: () => 'unknown'
};

const lodgingBookingStatus: EnumHelpers<EventLodgingBookingStatusEnum | UnknownEnum> = {
  enumValues: [
    EventLodgingBookingStatusEnum.booked,
    EventLodgingBookingStatusEnum.inProgress,
    EventLodgingBookingStatusEnum.toBook,
    UnknownEnum.unknown
  ],
  getLabel: (value) => {
    switch (value) {
      case EventLodgingBookingStatusEnum.booked:
        return 'Booked';
      case EventLodgingBookingStatusEnum.toBook:
        return 'To Book';
      case EventLodgingBookingStatusEnum.inProgress:
        return 'In Progress';
      default:
        return 'Unknown';
    }
  },
  getColor: (value) => {
    switch (value) {
      case EventLodgingBookingStatusEnum.booked:
        return 'success';
      case EventLodgingBookingStatusEnum.inProgress:
        return 'warning';
      case EventLodgingBookingStatusEnum.toBook:
        return 'error';
      default:
        return 'unknown';
    }
  }
};

const staffStatus: EnumHelpers<EventStaffStatusEnum> = {
  enumValues: [
    EventStaffStatusEnum.fulfilled,
    EventStaffStatusEnum.pending,
  ],
  getLabel: (value) => {
    switch (value) {
      case EventStaffStatusEnum.fulfilled:
        return 'Fulfilled';
      case EventStaffStatusEnum.pending:
      default:
        return 'Pending';
    }
  },
  getColor: (value) => {
    switch (value) {
      case EventStaffStatusEnum.fulfilled:
        return 'success';
      default:
        return 'warning';
    }
  }
};

const datesStatus: EnumHelpers<EventDatesStatusEnum> = {
  enumValues: [
    EventDatesStatusEnum.complete,
    EventDatesStatusEnum.attention,
  ],
  getLabel: (value) => {
    switch (value) {
      case EventDatesStatusEnum.complete:
        return 'Complete';
      default:
        return 'Attention';
    }
  },
  getColor: (value) => {
    switch (value) {
      case EventDatesStatusEnum.complete:
        return 'success';
      default:
        return 'warning';
    }
  }
};

const forResourceType: EnumHelpers<EventForResourceTypeEnum> = {
  enumValues: [
    EventForResourceTypeEnum.current,
    EventForResourceTypeEnum.upcoming,
  ],
  getLabel: (value) => {
    switch (value) {
      case EventForResourceTypeEnum.current:
        return 'Current';
      default:
        return 'Upcoming';
    }
  },
  getColor: () => 'unknown'
};

const indoorOutdoor: EnumHelpers<EventIndoorOurdoorEnum | UnknownEnum> = {
  enumValues: [
    EventIndoorOurdoorEnum.indoor,
    EventIndoorOurdoorEnum.outdoor,
    UnknownEnum.unknown
  ],
  getLabel: (value) => {
    switch (value) {
      case EventIndoorOurdoorEnum.indoor:
        return 'Indoor';
      case EventIndoorOurdoorEnum.outdoor:
        return 'Outdoor';
      default:
        return 'Unknown';
    }
  },
  getColor: () => 'unknown'
};

const futureInterestStatus: EnumHelpers<EventFutureInterestStatusEnum | UnknownEnum> = {
  enumValues: [
    EventFutureInterestStatusEnum.yes,
    EventFutureInterestStatusEnum.yesAskNextYear,
    EventFutureInterestStatusEnum.no,
    UnknownEnum.unknown
  ],
  getLabel: (value) => {
    switch (value) {
      case EventFutureInterestStatusEnum.yes:
        return 'Yes, repeat';
      case EventFutureInterestStatusEnum.yesAskNextYear:
        return 'Yes, ask again next year';
      case EventFutureInterestStatusEnum.no:
        return 'No';
      default:
        return 'Unknown';
    }
  },
  getColor: () => 'unknown'
};

const electricityChoice: EnumHelpers<EventElectricityChoiceEnum | UnknownEnum> = {
  enumValues: [
    EventElectricityChoiceEnum.generator,
    EventElectricityChoiceEnum.offered,
    UnknownEnum.unknown
  ],
  getLabel: (value) => {
    switch (value) {
      case EventElectricityChoiceEnum.generator:
        return 'Use generator';
      case EventElectricityChoiceEnum.offered:
        return 'Use electricity offered at a show';
      default:
        return 'Unknown';
    }
  },
  getColor: () => 'unknown'
};

export const eventEnumHelpers = {
  applicationStatus,
  acceptanceStatus,
  applicationPlatform,
  participationStatus,
  paymentType,
  insuranceStatus,
  insuranceShared,
  communicationLog,
  file,
  lodgingBookingStatus,
  staffStatus,
  datesStatus,
  forResourceType,
  indoorOutdoor,
  futureInterestStatus,
  electricityChoice,
};