import { useTeams } from 'queries';
import React, { useContext, useMemo, useRef, useState } from 'react';
import { EditModeToggler, Loading, TableActionsBar, TeamsSchedulingFullCalendar, TeamsSchedulingFullCalendarEditable, useEventPreviewDrawerContext } from 'components';
import { EventsManagementInfoPopper } from 'pages/EventsManagement/components';
import { EventsManagementPageTitle } from 'pages/EventsManagement/components/EventsManagementPageTitle.component';
import { EventsManagementTreeViewNodeIdEnum } from 'pages/EventsManagement/helpers';
import { Box } from '@mui/material';
import { EventsManagementPageContext } from 'pages/EventsManagement/EventsManagement.page';
import { EventPreperationParticipationStatusEnum } from 'api/resources';
import { ConfirmTeamChangeModal } from './components';
import { getEventsSummaryHash } from 'components/FullCalendar/custom/helpers';
import { useContainerQuery, useFullCalendarWithEditModeDateLocalStorage } from 'hooks';

const CALENDAR_ID = EventsManagementTreeViewNodeIdEnum.logisticsTeamAssignmentCalendar;
const CALENDAR_EDITABLE_ID = `${EventsManagementTreeViewNodeIdEnum.logisticsTeamAssignmentCalendar}_editable`;

export const LogisticsTeamAssignmentCalendarSwitch = () => {
  const { setOpenEvent } = useEventPreviewDrawerContext();
  const { data: teams = [], isInitialLoading: teamsLoading } = useTeams();
  const [ isEditMode, setIsEditMode ] = useState(false);
  const { events, eventsLoading } = useContext(EventsManagementPageContext);
  const filteredEvents = events.filter(event => event.participationStatus && [ EventPreperationParticipationStatusEnum.participating, EventPreperationParticipationStatusEnum.interested ].includes(event.participationStatus));
  const initialDate = useFullCalendarWithEditModeDateLocalStorage(CALENDAR_ID, CALENDAR_EDITABLE_ID, isEditMode);
  const containerRef = useRef<HTMLElement>(null);
  const isMediumOrSmall = useContainerQuery({ containerRef, query: theme => theme.breakpoints.down('lg') });

  const eventsSummaryHash = useMemo(() => getEventsSummaryHash(filteredEvents), [ filteredEvents ]);

  const calendarDisplay = useMemo(() => {
    return (
      <TeamsSchedulingFullCalendar
        id={CALENDAR_ID}
        initialDate={initialDate}
        events={filteredEvents}
        teams={teams}
        eventsSummaryHash={eventsSummaryHash}
        onEventClick={(eventId) => setOpenEvent(eventId, 'scheduling')}
        containerIsMediumOrSmall={isMediumOrSmall}
      />
    );
  }, [ eventsSummaryHash, filteredEvents, initialDate, isMediumOrSmall, setOpenEvent, teams ]);

  const calendarEditableDisplay = useMemo(() => {
    return (
      <TeamsSchedulingFullCalendarEditable
        id={CALENDAR_ID}
        calendarId={CALENDAR_EDITABLE_ID}
        initialDate={initialDate}
        events={filteredEvents}
        teams={teams}
        eventsSummaryHash={eventsSummaryHash}
        renderTeamChangeModal={params => <ConfirmTeamChangeModal {...params} />}
        onEventClick={(eventId) => setOpenEvent(eventId, 'teamScheduling')}
        containerIsMediumOrSmall={isMediumOrSmall}
      />
    );
  }, [ eventsSummaryHash, filteredEvents, initialDate, isMediumOrSmall, setOpenEvent, teams ]);

  if (eventsLoading || teamsLoading) {
    return <Loading />;
  }

  return (
    <Box ref={containerRef}>
      <TableActionsBar
        rightActions={(
          <Box display="flex" alignItems="flex-start" gap={3}>
            <EditModeToggler isEditMode={isEditMode} setIsEditMode={setIsEditMode} />
            <EventsManagementInfoPopper tab={EventsManagementTreeViewNodeIdEnum.logisticsTeamAssignmentCalendar} />
          </Box>
        )}
        leftActions={<EventsManagementPageTitle nodeId={EventsManagementTreeViewNodeIdEnum.logisticsTeamAssignmentCalendar} />}
      />
      {isEditMode ? calendarEditableDisplay : calendarDisplay}
    </Box>
  );
};
